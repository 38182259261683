.projectCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    gap: 1rem;
    margin-top: 2.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 1rem;
    --tw-shadow-color: var(--nav-shadow);
    --tw-shadow: var(--tw-shadow-colored);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
}

.projectCard > h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-align: center;
    width: 80%;
    font-weight: 40%;
}

.projectCard > img {
    color: transparent;
    border-radius: 0.25rem;
    width: 80%;
    height: auto;
    right: 0;
    position: relative;
    object-fit: cover;
    max-width: 100%;
    display: block;
}

.projectCard__info {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 0.5rem;
    width: 100%;
    position: relative;
}

.projectCard__info p {
    font-weight: 700;
    text-align: center;
    padding: 1rem;
    width: 70%;
    border-radius: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-top: 0.5rem;
}

.projectCard__info div {
    justify-content: center;
    width: 40%;
    margin-top: 0.75rem;
    padding: 1rem;
    gap: 0.5rem;
    display: flex;
}

.projectCard__links {
    display: flex;
    padding-right: 1.25rem;
    justify-content: flex-end;
    width: 40%;
    margin-bottom: 0;
    margin-top: 1.25rem;
    gap: 1.25rem;
}

.projectCard__links a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
.othersProjects {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.othersProjects__card {
    --tw-shadow-color: var(--nav-shadow);
    --tw-shadow: var(--tw-shadow-colored);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    padding: 1.25rem;
    border-color: var(--hover-nav);
    border-width: 1px;
    border-radius: 0.5rem;
    margin-top: 1.25rem;

    margin-left: 2rem;
    margin-right: 2rem;
}

.othersProjects__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.othersProjects__links > img {
    width: 3.5rem;
    height: 3.5rem;
    display: block;
}

.othersProjects__links > div {
    gap: 0.75rem;
    display: flex;
}

.othersProjects__links > div img {
    cursor: pòinter;
    width: 2.5rem;
    height: 2.5rem;
}

.othersProjects__card h1 {
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem;
    text-align: center;
    margin-top: 1rem;
}

.othersProjects__card p {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-top: 0.75rem;
}

.othersProjects__labels {
    font-weight: 700;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 1rem;
}

@media (max-width: 800px) {
    #projects > section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .projectCard {
        flex-direction: column;
        margin-top: 2.5rem;
        margin-left: 0;
        margin-right: 0;
        height: auto;
        width: 90%;
        border-radius: 1rem;
        --tw-shadow-color: var(--nav-shadow);
        --tw-shadow: var(--tw-shadow-colored);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
            var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    }

    .projectCard > img {
        width: 100%;
        height: auto;
        position: relative;
        max-width: 100%;
    }

    .projectCard__info {
        align-items: center;
        position: relative;
    }

    .projectCard__info h1 {
        width: 90%;
        text-align: center;
        font-size: 2rem;
    }

    .projectCard__info p {
        width: 100%;
        font-size: 1rem;
        margin-top: 0;
    }

    .projectCard__info ul {
        width: 100%;
        margin-top: 0;
    }

    .projectCard__links {
        margin-top: 0;
        justify-content: center;
    }

    .othersProjects {
        display: flex;
        flex-direction: column;
    }
}
