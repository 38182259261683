.stars__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 1%;
    height: 1%;
    z-index: -1;
}

#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 117px 1613px var(--stars), 1488px 635px var(--stars),
        944px 914px var(--stars), 647px 277px var(--stars),
        1792px 1205px var(--stars), 656px 1517px var(--stars),
        820px 1839px var(--stars), 1153px 1400px var(--stars),
        870px 13px var(--stars), 550px 702px var(--stars),
        1155px 1056px var(--stars), 88px 1709px var(--stars),
        1450px 1090px var(--stars), 1929px 457px var(--stars),
        1390px 905px var(--stars), 1771px 269px var(--stars),
        1741px 669px var(--stars), 432px 64px var(--stars),
        563px 996px var(--stars), 1918px 1873px var(--stars),
        1845px 1211px var(--stars), 231px 1503px var(--stars),
        37px 220px var(--stars), 1970px 495px var(--stars),
        1812px 925px var(--stars), 67px 1398px var(--stars),
        535px 279px var(--stars), 1837px 829px var(--stars),
        1945px 685px var(--stars), 1677px 1817px var(--stars),
        1317px 1415px var(--stars), 1785px 905px var(--stars),
        1787px 1554px var(--stars), 802px 1296px var(--stars),
        512px 1101px var(--stars), 583px 1364px var(--stars),
        336px 558px var(--stars), 979px 334px var(--stars),
        106px 792px var(--stars), 204px 34px var(--stars),
        1845px 1763px var(--stars), 445px 1599px var(--stars),
        386px 453px var(--stars), 471px 952px var(--stars),
        1466px 1676px var(--stars), 1885px 303px var(--stars),
        51px 1717px var(--stars), 1211px 299px var(--stars),
        1546px 1887px var(--stars), 1067px 33px var(--stars),
        1088px 1326px var(--stars), 1938px 760px var(--stars),
        470px 648px var(--stars), 1213px 269px var(--stars),
        1767px 78px var(--stars), 977px 976px var(--stars),
        1926px 175px var(--stars), 722px 1512px var(--stars),
        945px 227px var(--stars), 1811px 99px var(--stars),
        1912px 1406px var(--stars), 1602px 1243px var(--stars),
        610px 449px var(--stars), 654px 1393px var(--stars),
        1930px 1193px var(--stars), 258px 1184px var(--stars),
        89px 265px var(--stars), 824px 1494px var(--stars),
        1506px 1435px var(--stars), 1027px 753px var(--stars),
        1px 1197px var(--stars), 530px 1161px var(--stars),
        864px 1555px var(--stars), 1610px 1604px var(--stars),
        1035px 1114px var(--stars), 1456px 133px var(--stars),
        1196px 1253px var(--stars), 361px 1037px var(--stars),
        834px 351px var(--stars), 436px 1676px var(--stars),
        1194px 1007px var(--stars), 1141px 647px var(--stars),
        319px 454px var(--stars), 937px 1769px var(--stars),
        1872px 1013px var(--stars), 733px 643px var(--stars),
        1250px 511px var(--stars), 189px 296px var(--stars),
        1639px 163px var(--stars), 1584px 336px var(--stars),
        1912px 1343px var(--stars), 1298px 1307px var(--stars),
        1750px 902px var(--stars), 1129px 845px var(--stars),
        1899px 1470px var(--stars), 1427px 232px var(--stars),
        1391px 838px var(--stars), 1225px 1819px var(--stars),
        190px 1366px var(--stars), 1865px 518px var(--stars),
        203px 1383px var(--stars), 1455px 614px var(--stars),
        423px 354px var(--stars), 1678px 1790px var(--stars),
        241px 608px var(--stars), 1089px 730px var(--stars),
        1342px 38px var(--stars), 1848px 249px var(--stars),
        1874px 1785px var(--stars), 1040px 1837px var(--stars),
        751px 261px var(--stars), 510px 1975px var(--stars),
        52px 795px var(--stars), 1786px 1310px var(--stars),
        498px 712px var(--stars), 190px 375px var(--stars),
        1341px 722px var(--stars), 43px 1394px var(--stars),
        1821px 1687px var(--stars), 106px 130px var(--stars),
        1717px 1978px var(--stars), 168px 151px var(--stars),
        183px 740px var(--stars), 945px 1381px var(--stars),
        669px 1170px var(--stars), 1285px 1816px var(--stars),
        110px 1217px var(--stars), 1623px 813px var(--stars),
        869px 647px var(--stars), 867px 582px var(--stars),
        735px 1240px var(--stars), 519px 1896px var(--stars),
        132px 156px var(--stars), 1649px 193px var(--stars),
        241px 1109px var(--stars), 643px 484px var(--stars),
        574px 1282px var(--stars), 1952px 564px var(--stars),
        1978px 145px var(--stars), 329px 903px var(--stars),
        1674px 617px var(--stars), 1978px 558px var(--stars),
        1808px 1715px var(--stars), 1526px 1238px var(--stars),
        475px 1330px var(--stars), 810px 425px var(--stars),
        1709px 634px var(--stars), 1658px 336px var(--stars),
        425px 194px var(--stars), 352px 96px var(--stars),
        148px 180px var(--stars), 1139px 1046px var(--stars),
        1809px 1233px var(--stars), 1669px 171px var(--stars),
        263px 1394px var(--stars), 534px 715px var(--stars),
        396px 1008px var(--stars), 589px 1445px var(--stars),
        1190px 381px var(--stars), 1709px 279px var(--stars),
        520px 891px var(--stars), 1136px 1867px var(--stars),
        1280px 1233px var(--stars), 836px 296px var(--stars),
        1348px 646px var(--stars), 1539px 913px var(--stars),
        423px 781px var(--stars), 1271px 1805px var(--stars),
        696px 564px var(--stars), 1549px 804px var(--stars),
        303px 1555px var(--stars), 1449px 1903px var(--stars),
        66px 687px var(--stars), 1164px 856px var(--stars),
        1958px 1326px var(--stars), 125px 157px var(--stars),
        508px 1669px var(--stars), 465px 725px var(--stars),
        1925px 1440px var(--stars), 405px 793px var(--stars),
        278px 110px var(--stars), 1084px 1065px var(--stars),
        1077px 705px var(--stars), 663px 1844px var(--stars),
        734px 263px var(--stars), 870px 1761px var(--stars),
        103px 1169px var(--stars), 1506px 1295px var(--stars),
        1883px 926px var(--stars), 335px 1361px var(--stars),
        1126px 1284px var(--stars), 257px 1165px var(--stars),
        837px 580px var(--stars), 1211px 1362px var(--stars),
        1137px 1380px var(--stars), 135px 632px var(--stars),
        1491px 1965px var(--stars), 1098px 195px var(--stars),
        506px 417px var(--stars), 693px 1243px var(--stars),
        622px 1862px var(--stars), 1412px 1343px var(--stars),
        948px 1894px var(--stars), 1315px 1363px var(--stars),
        754px 1098px var(--stars), 1931px 930px var(--stars),
        1831px 342px var(--stars), 1751px 1839px var(--stars),
        84px 775px var(--stars), 1662px 1488px var(--stars),
        617px 1769px var(--stars), 1869px 1292px var(--stars),
        963px 432px var(--stars), 371px 1114px var(--stars),
        37px 642px var(--stars), 21px 1184px var(--stars),
        602px 366px var(--stars), 414px 524px var(--stars),
        282px 244px var(--stars), 1689px 868px var(--stars),
        943px 681px var(--stars), 898px 679px var(--stars),
        449px 1774px var(--stars), 1678px 1313px var(--stars),
        475px 1811px var(--stars), 1146px 1509px var(--stars),
        1151px 1863px var(--stars), 1617px 846px var(--stars),
        82px 1077px var(--stars), 324px 1317px var(--stars),
        1516px 885px var(--stars), 1706px 1526px var(--stars),
        1925px 1180px var(--stars), 553px 967px var(--stars),
        1072px 536px var(--stars), 1715px 1816px var(--stars),
        185px 286px var(--stars), 1362px 1600px var(--stars),
        628px 1938px var(--stars), 1187px 412px var(--stars),
        569px 211px var(--stars), 1959px 1356px var(--stars),
        1571px 105px var(--stars), 319px 1111px var(--stars),
        36px 1364px var(--stars), 502px 1788px var(--stars),
        1051px 1993px var(--stars), 1617px 773px var(--stars),
        424px 1507px var(--stars), 1623px 1955px var(--stars),
        307px 662px var(--stars), 183px 1048px var(--stars),
        1919px 1453px var(--stars), 1006px 1817px var(--stars),
        468px 673px var(--stars), 1142px 1375px var(--stars),
        1228px 443px var(--stars), 1734px 552px var(--stars),
        20px 1041px var(--stars), 1783px 334px var(--stars),
        98px 1237px var(--stars), 1356px 1940px var(--stars),
        853px 1779px var(--stars), 1910px 560px var(--stars),
        1174px 1656px var(--stars), 110px 1724px var(--stars),
        542px 1771px var(--stars), 1758px 1931px var(--stars),
        1463px 1401px var(--stars), 1155px 84px var(--stars),
        1504px 835px var(--stars), 750px 322px var(--stars),
        407px 1900px var(--stars), 1600px 1141px var(--stars),
        657px 886px var(--stars), 526px 714px var(--stars),
        18px 836px var(--stars), 1546px 1548px var(--stars),
        22px 469px var(--stars), 594px 1466px var(--stars),
        1160px 1078px var(--stars), 627px 1055px var(--stars),
        195px 699px var(--stars), 1099px 684px var(--stars),
        530px 551px var(--stars), 1160px 1325px var(--stars),
        894px 727px var(--stars), 1157px 98px var(--stars),
        136px 1483px var(--stars), 1875px 1975px var(--stars),
        1803px 566px var(--stars), 318px 1073px var(--stars),
        1866px 1656px var(--stars), 543px 414px var(--stars),
        719px 474px var(--stars), 1115px 738px var(--stars),
        353px 875px var(--stars), 184px 1938px var(--stars),
        1854px 1534px var(--stars), 420px 1698px var(--stars),
        1480px 1550px var(--stars), 522px 203px var(--stars),
        1897px 1904px var(--stars), 975px 1708px var(--stars),
        1774px 602px var(--stars), 1908px 274px var(--stars),
        61px 715px var(--stars), 983px 1156px var(--stars),
        326px 1013px var(--stars), 641px 290px var(--stars),
        1522px 120px var(--stars), 405px 1637px var(--stars),
        1021px 1099px var(--stars), 631px 1145px var(--stars),
        982px 1967px var(--stars), 200px 651px var(--stars),
        795px 351px var(--stars), 790px 1082px var(--stars),
        144px 1572px var(--stars), 1542px 901px var(--stars),
        158px 1524px var(--stars), 849px 1843px var(--stars),
        1807px 203px var(--stars), 1747px 45px var(--stars),
        1603px 1738px var(--stars), 617px 1966px var(--stars),
        342px 748px var(--stars), 1779px 1173px var(--stars),
        1428px 152px var(--stars), 589px 1998px var(--stars),
        1940px 1838px var(--stars), 115px 272px var(--stars),
        1217px 1395px var(--stars), 1402px 1491px var(--stars),
        1833px 1814px var(--stars), 243px 966px var(--stars),
        319px 578px var(--stars), 813px 364px var(--stars),
        669px 882px var(--stars), 551px 134px var(--stars),
        1819px 920px var(--stars), 740px 1826px var(--stars),
        1021px 952px var(--stars), 1575px 453px var(--stars),
        324px 419px var(--stars), 929px 417px var(--stars),
        885px 1112px var(--stars), 503px 187px var(--stars),
        1908px 362px var(--stars), 1063px 1601px var(--stars),
        169px 1792px var(--stars), 789px 963px var(--stars),
        1697px 948px var(--stars), 1761px 1810px var(--stars),
        1844px 1591px var(--stars), 1709px 949px var(--stars),
        1402px 1396px var(--stars), 1037px 225px var(--stars),
        1832px 518px var(--stars), 1728px 1782px var(--stars),
        194px 1421px var(--stars), 1395px 742px var(--stars),
        1478px 1325px var(--stars), 40px 593px var(--stars),
        1732px 117px var(--stars), 51px 158px var(--stars),
        1598px 1672px var(--stars), 701px 849px var(--stars),
        1403px 1979px var(--stars), 145px 1414px var(--stars),
        550px 906px var(--stars), 1366px 460px var(--stars),
        142px 1379px var(--stars), 34px 1864px var(--stars),
        1346px 308px var(--stars), 293px 998px var(--stars),
        21px 1868px var(--stars), 540px 1033px var(--stars),
        60px 746px var(--stars), 1602px 1476px var(--stars),
        180px 804px var(--stars), 345px 1982px var(--stars),
        1439px 640px var(--stars), 939px 1834px var(--stars),
        20px 432px var(--stars), 492px 1549px var(--stars),
        109px 1579px var(--stars), 1796px 1403px var(--stars),
        1079px 519px var(--stars), 1664px 389px var(--stars),
        1627px 1061px var(--stars), 823px 419px var(--stars),
        1399px 1882px var(--stars), 1906px 344px var(--stars),
        1189px 848px var(--stars), 117px 882px var(--stars),
        1262px 33px var(--stars), 1048px 434px var(--stars),
        1208px 1309px var(--stars), 1616px 408px var(--stars),
        1833px 853px var(--stars), 1433px 1656px var(--stars),
        811px 1861px var(--stars), 439px 1672px var(--stars),
        1105px 248px var(--stars), 328px 1652px var(--stars),
        13px 1658px var(--stars), 685px 987px var(--stars),
        985px 403px var(--stars), 1664px 1206px var(--stars),
        1993px 1925px var(--stars), 440px 917px var(--stars),
        1835px 319px var(--stars), 1404px 1907px var(--stars),
        624px 1443px var(--stars), 843px 954px var(--stars),
        478px 1567px var(--stars), 895px 1602px var(--stars),
        1231px 871px var(--stars), 1267px 1646px var(--stars),
        475px 334px var(--stars), 784px 796px var(--stars),
        1294px 199px var(--stars), 109px 702px var(--stars),
        1978px 362px var(--stars), 291px 940px var(--stars),
        971px 1343px var(--stars), 74px 719px var(--stars),
        36px 715px var(--stars), 1007px 1423px var(--stars),
        860px 314px var(--stars), 631px 177px var(--stars),
        1900px 1590px var(--stars), 1239px 1348px var(--stars),
        1346px 1270px var(--stars), 1934px 1475px var(--stars),
        1553px 559px var(--stars), 588px 1969px var(--stars),
        670px 1269px var(--stars), 1484px 376px var(--stars),
        20px 1424px var(--stars), 1396px 8px var(--stars),
        969px 244px var(--stars), 1807px 538px var(--stars),
        1873px 891px var(--stars), 636px 1142px var(--stars),
        1474px 1562px var(--stars), 763px 350px var(--stars),
        663px 700px var(--stars), 500px 1469px var(--stars),
        1302px 722px var(--stars), 181px 291px var(--stars),
        266px 893px var(--stars), 1403px 654px var(--stars),
        492px 460px var(--stars), 1503px 1369px var(--stars),
        23px 1662px var(--stars), 349px 333px var(--stars),
        1435px 1017px var(--stars), 1441px 705px var(--stars),
        1708px 1446px var(--stars), 1041px 911px var(--stars),
        1063px 780px var(--stars), 1158px 1356px var(--stars),
        767px 1454px var(--stars), 1912px 797px var(--stars),
        1731px 1759px var(--stars), 1378px 1390px var(--stars),
        1815px 1364px var(--stars), 960px 270px var(--stars),
        1343px 427px var(--stars), 275px 203px var(--stars),
        1319px 1092px var(--stars), 1455px 770px var(--stars),
        283px 1503px var(--stars), 1505px 901px var(--stars),
        1738px 1561px var(--stars), 1526px 1935px var(--stars),
        1757px 669px var(--stars), 1640px 620px var(--stars),
        1750px 722px var(--stars), 748px 66px var(--stars),
        1149px 540px var(--stars), 159px 953px var(--stars),
        200px 1426px var(--stars), 515px 1110px var(--stars),
        1552px 737px var(--stars), 1094px 1459px var(--stars),
        778px 799px var(--stars), 1031px 523px var(--stars),
        743px 1825px var(--stars), 1100px 882px var(--stars),
        1088px 1836px var(--stars), 255px 599px var(--stars),
        67px 1361px var(--stars), 247px 1721px var(--stars),
        1722px 346px var(--stars), 1822px 155px var(--stars),
        452px 1973px var(--stars), 415px 1960px var(--stars),
        1109px 57px var(--stars), 273px 1392px var(--stars),
        404px 1071px var(--stars), 1212px 353px var(--stars),
        370px 460px var(--stars), 795px 1523px var(--stars),
        1932px 340px var(--stars), 51px 1473px var(--stars),
        1268px 364px var(--stars), 1512px 1862px var(--stars),
        1678px 1801px var(--stars), 1796px 579px var(--stars),
        254px 251px var(--stars), 1466px 1717px var(--stars),
        893px 379px var(--stars), 1153px 923px var(--stars),
        913px 1808px var(--stars), 791px 789px var(--stars),
        417px 1924px var(--stars), 1336px 1599px var(--stars),
        1695px 908px var(--stars), 1120px 114px var(--stars),
        493px 1949px var(--stars), 68px 1905px var(--stars),
        969px 481px var(--stars), 1420px 1095px var(--stars),
        800px 1117px var(--stars), 390px 234px var(--stars),
        356px 1644px var(--stars), 1098px 1486px var(--stars),
        1360px 521px var(--stars), 149px 1198px var(--stars),
        354px 747px var(--stars), 1749px 487px var(--stars),
        470px 76px var(--stars), 1672px 289px var(--stars),
        1731px 545px var(--stars), 1547px 1590px var(--stars),
        498px 692px var(--stars), 398px 1592px var(--stars),
        1846px 1237px var(--stars), 1537px 1474px var(--stars),
        1726px 1374px var(--stars), 1922px 858px var(--stars),
        376px 321px var(--stars), 985px 227px var(--stars),
        234px 1421px var(--stars), 760px 745px var(--stars),
        1990px 1132px var(--stars), 1560px 1597px var(--stars),
        338px 1310px var(--stars), 1924px 1664px var(--stars),
        547px 1747px var(--stars), 1639px 1282px var(--stars),
        1202px 337px var(--stars), 1985px 779px var(--stars),
        737px 456px var(--stars), 89px 501px var(--stars),
        963px 792px var(--stars), 655px 1447px var(--stars),
        1492px 1994px var(--stars), 1171px 254px var(--stars),
        892px 827px var(--stars), 1735px 442px var(--stars),
        1474px 1187px var(--stars), 846px 1518px var(--stars),
        557px 1805px var(--stars), 738px 945px var(--stars),
        795px 68px var(--stars), 663px 1956px var(--stars),
        1607px 290px var(--stars), 1524px 15px var(--stars),
        1097px 1911px var(--stars), 157px 1939px var(--stars),
        935px 1065px var(--stars), 1809px 1708px var(--stars),
        164px 1157px var(--stars), 83px 855px var(--stars),
        625px 501px var(--stars), 814px 398px var(--stars),
        552px 695px var(--stars), 597px 1546px var(--stars),
        1237px 1417px var(--stars), 628px 284px var(--stars),
        866px 767px var(--stars), 1403px 1394px var(--stars),
        765px 1563px var(--stars), 1648px 109px var(--stars),
        1205px 1659px var(--stars), 921px 1313px var(--stars),
        1319px 243px var(--stars), 18px 125px var(--stars),
        7px 777px var(--stars), 181px 418px var(--stars),
        1062px 1892px var(--stars), 382px 106px var(--stars),
        994px 751px var(--stars), 964px 234px var(--stars),
        40px 118px var(--stars), 278px 706px var(--stars),
        1540px 1978px var(--stars), 425px 1661px var(--stars),
        1050px 321px var(--stars), 735px 1729px var(--stars),
        1438px 260px var(--stars), 1229px 1109px var(--stars),
        186px 1041px var(--stars), 244px 1184px var(--stars),
        392px 1472px var(--stars), 670px 1249px var(--stars),
        1260px 1443px var(--stars), 1977px 1511px var(--stars),
        1240px 773px var(--stars), 303px 513px var(--stars),
        63px 1530px var(--stars), 610px 792px var(--stars),
        1987px 1647px var(--stars), 676px 1597px var(--stars),
        1740px 1244px var(--stars), 816px 1661px var(--stars),
        351px 802px var(--stars), 252px 1082px var(--stars),
        31px 365px var(--stars), 1453px 984px var(--stars),
        667px 1233px var(--stars), 1247px 1800px var(--stars),
        839px 270px var(--stars), 775px 913px var(--stars),
        1966px 1398px var(--stars), 499px 813px var(--stars),
        922px 1982px var(--stars), 1409px 1902px var(--stars),
        1499px 1766px var(--stars), 721px 899px var(--stars),
        788px 807px var(--stars), 989px 1355px var(--stars),
        1248px 1274px var(--stars), 849px 1091px var(--stars),
        1799px 1036px var(--stars), 1486px 700px var(--stars),
        170px 1989px var(--stars), 1275px 799px var(--stars),
        772px 2000px var(--stars), 1642px 362px var(--stars),
        216px 940px var(--stars), 1893px 281px var(--stars),
        1944px 1298px var(--stars), 1294px 400px var(--stars),
        1523px 441px var(--stars), 1829px 340px var(--stars),
        468px 170px var(--stars), 1099px 967px var(--stars),
        1331px 665px var(--stars), 1174px 1553px var(--stars),
        1567px 325px var(--stars), 1028px 1399px var(--stars),
        781px 1451px var(--stars), 1912px 1954px var(--stars),
        874px 873px var(--stars), 1298px 1722px var(--stars),
        1879px 706px var(--stars), 57px 1221px var(--stars),
        1116px 1432px var(--stars), 48px 811px var(--stars),
        101px 916px var(--stars), 677px 304px var(--stars),
        1203px 639px var(--stars), 1391px 199px var(--stars),
        1895px 1988px var(--stars), 1462px 1023px var(--stars),
        1216px 1751px var(--stars), 1261px 663px var(--stars),
        1290px 1119px var(--stars), 137px 1793px var(--stars),
        1052px 1470px var(--stars), 1561px 226px var(--stars),
        1156px 402px var(--stars), 709px 693px var(--stars),
        1040px 1911px var(--stars), 1624px 1115px var(--stars),
        551px 475px var(--stars), 416px 1090px var(--stars),
        1183px 451px var(--stars), 58px 765px var(--stars),
        743px 1016px var(--stars), 198px 369px var(--stars),
        1645px 1503px var(--stars), 997px 22px var(--stars),
        1447px 1323px var(--stars), 379px 883px var(--stars),
        1171px 1195px var(--stars), 919px 133px var(--stars),
        1400px 517px var(--stars), 725px 804px var(--stars),
        1600px 699px var(--stars), 357px 581px var(--stars),
        266px 1713px var(--stars), 848px 1749px var(--stars),
        1963px 1045px var(--stars), 119px 1136px var(--stars);
    animation: animStar 50s linear infinite;
}
#stars:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 117px 1613px var(--stars), 1488px 635px var(--stars),
        944px 914px var(--stars), 647px 277px var(--stars),
        1792px 1205px var(--stars), 656px 1517px var(--stars),
        820px 1839px var(--stars), 1153px 1400px var(--stars),
        870px 13px var(--stars), 550px 702px var(--stars),
        1155px 1056px var(--stars), 88px 1709px var(--stars),
        1450px 1090px var(--stars), 1929px 457px var(--stars),
        1390px 905px var(--stars), 1771px 269px var(--stars),
        1741px 669px var(--stars), 432px 64px var(--stars),
        563px 996px var(--stars), 1918px 1873px var(--stars),
        1845px 1211px var(--stars), 231px 1503px var(--stars),
        37px 220px var(--stars), 1970px 495px var(--stars),
        1812px 925px var(--stars), 67px 1398px var(--stars),
        535px 279px var(--stars), 1837px 829px var(--stars),
        1945px 685px var(--stars), 1677px 1817px var(--stars),
        1317px 1415px var(--stars), 1785px 905px var(--stars),
        1787px 1554px var(--stars), 802px 1296px var(--stars),
        512px 1101px var(--stars), 583px 1364px var(--stars),
        336px 558px var(--stars), 979px 334px var(--stars),
        106px 792px var(--stars), 204px 34px var(--stars),
        1845px 1763px var(--stars), 445px 1599px var(--stars),
        386px 453px var(--stars), 471px 952px var(--stars),
        1466px 1676px var(--stars), 1885px 303px var(--stars),
        51px 1717px var(--stars), 1211px 299px var(--stars),
        1546px 1887px var(--stars), 1067px 33px var(--stars),
        1088px 1326px var(--stars), 1938px 760px var(--stars),
        470px 648px var(--stars), 1213px 269px var(--stars),
        1767px 78px var(--stars), 977px 976px var(--stars),
        1926px 175px var(--stars), 722px 1512px var(--stars),
        945px 227px var(--stars), 1811px 99px var(--stars),
        1912px 1406px var(--stars), 1602px 1243px var(--stars),
        610px 449px var(--stars), 654px 1393px var(--stars),
        1930px 1193px var(--stars), 258px 1184px var(--stars),
        89px 265px var(--stars), 824px 1494px var(--stars),
        1506px 1435px var(--stars), 1027px 753px var(--stars),
        1px 1197px var(--stars), 530px 1161px var(--stars),
        864px 1555px var(--stars), 1610px 1604px var(--stars),
        1035px 1114px var(--stars), 1456px 133px var(--stars),
        1196px 1253px var(--stars), 361px 1037px var(--stars),
        834px 351px var(--stars), 436px 1676px var(--stars),
        1194px 1007px var(--stars), 1141px 647px var(--stars),
        319px 454px var(--stars), 937px 1769px var(--stars),
        1872px 1013px var(--stars), 733px 643px var(--stars),
        1250px 511px var(--stars), 189px 296px var(--stars),
        1639px 163px var(--stars), 1584px 336px var(--stars),
        1912px 1343px var(--stars), 1298px 1307px var(--stars),
        1750px 902px var(--stars), 1129px 845px var(--stars),
        1899px 1470px var(--stars), 1427px 232px var(--stars),
        1391px 838px var(--stars), 1225px 1819px var(--stars),
        190px 1366px var(--stars), 1865px 518px var(--stars),
        203px 1383px var(--stars), 1455px 614px var(--stars),
        423px 354px var(--stars), 1678px 1790px var(--stars),
        241px 608px var(--stars), 1089px 730px var(--stars),
        1342px 38px var(--stars), 1848px 249px var(--stars),
        1874px 1785px var(--stars), 1040px 1837px var(--stars),
        751px 261px var(--stars), 510px 1975px var(--stars),
        52px 795px var(--stars), 1786px 1310px var(--stars),
        498px 712px var(--stars), 190px 375px var(--stars),
        1341px 722px var(--stars), 43px 1394px var(--stars),
        1821px 1687px var(--stars), 106px 130px var(--stars),
        1717px 1978px var(--stars), 168px 151px var(--stars),
        183px 740px var(--stars), 945px 1381px var(--stars),
        669px 1170px var(--stars), 1285px 1816px var(--stars),
        110px 1217px var(--stars), 1623px 813px var(--stars),
        869px 647px var(--stars), 867px 582px var(--stars),
        735px 1240px var(--stars), 519px 1896px var(--stars),
        132px 156px var(--stars), 1649px 193px var(--stars),
        241px 1109px var(--stars), 643px 484px var(--stars),
        574px 1282px var(--stars), 1952px 564px var(--stars),
        1978px 145px var(--stars), 329px 903px var(--stars),
        1674px 617px var(--stars), 1978px 558px var(--stars),
        1808px 1715px var(--stars), 1526px 1238px var(--stars),
        475px 1330px var(--stars), 810px 425px var(--stars),
        1709px 634px var(--stars), 1658px 336px var(--stars),
        425px 194px var(--stars), 352px 96px var(--stars),
        148px 180px var(--stars), 1139px 1046px var(--stars),
        1809px 1233px var(--stars), 1669px 171px var(--stars),
        263px 1394px var(--stars), 534px 715px var(--stars),
        396px 1008px var(--stars), 589px 1445px var(--stars),
        1190px 381px var(--stars), 1709px 279px var(--stars),
        520px 891px var(--stars), 1136px 1867px var(--stars),
        1280px 1233px var(--stars), 836px 296px var(--stars),
        1348px 646px var(--stars), 1539px 913px var(--stars),
        423px 781px var(--stars), 1271px 1805px var(--stars),
        696px 564px var(--stars), 1549px 804px var(--stars),
        303px 1555px var(--stars), 1449px 1903px var(--stars),
        66px 687px var(--stars), 1164px 856px var(--stars),
        1958px 1326px var(--stars), 125px 157px var(--stars),
        508px 1669px var(--stars), 465px 725px var(--stars),
        1925px 1440px var(--stars), 405px 793px var(--stars),
        278px 110px var(--stars), 1084px 1065px var(--stars),
        1077px 705px var(--stars), 663px 1844px var(--stars),
        734px 263px var(--stars), 870px 1761px var(--stars),
        103px 1169px var(--stars), 1506px 1295px var(--stars),
        1883px 926px var(--stars), 335px 1361px var(--stars),
        1126px 1284px var(--stars), 257px 1165px var(--stars),
        837px 580px var(--stars), 1211px 1362px var(--stars),
        1137px 1380px var(--stars), 135px 632px var(--stars),
        1491px 1965px var(--stars), 1098px 195px var(--stars),
        506px 417px var(--stars), 693px 1243px var(--stars),
        622px 1862px var(--stars), 1412px 1343px var(--stars),
        948px 1894px var(--stars), 1315px 1363px var(--stars),
        754px 1098px var(--stars), 1931px 930px var(--stars),
        1831px 342px var(--stars), 1751px 1839px var(--stars),
        84px 775px var(--stars), 1662px 1488px var(--stars),
        617px 1769px var(--stars), 1869px 1292px var(--stars),
        963px 432px var(--stars), 371px 1114px var(--stars),
        37px 642px var(--stars), 21px 1184px var(--stars),
        602px 366px var(--stars), 414px 524px var(--stars),
        282px 244px var(--stars), 1689px 868px var(--stars),
        943px 681px var(--stars), 898px 679px var(--stars),
        449px 1774px var(--stars), 1678px 1313px var(--stars),
        475px 1811px var(--stars), 1146px 1509px var(--stars),
        1151px 1863px var(--stars), 1617px 846px var(--stars),
        82px 1077px var(--stars), 324px 1317px var(--stars),
        1516px 885px var(--stars), 1706px 1526px var(--stars),
        1925px 1180px var(--stars), 553px 967px var(--stars),
        1072px 536px var(--stars), 1715px 1816px var(--stars),
        185px 286px var(--stars), 1362px 1600px var(--stars),
        628px 1938px var(--stars), 1187px 412px var(--stars),
        569px 211px var(--stars), 1959px 1356px var(--stars),
        1571px 105px var(--stars), 319px 1111px var(--stars),
        36px 1364px var(--stars), 502px 1788px var(--stars),
        1051px 1993px var(--stars), 1617px 773px var(--stars),
        424px 1507px var(--stars), 1623px 1955px var(--stars),
        307px 662px var(--stars), 183px 1048px var(--stars),
        1919px 1453px var(--stars), 1006px 1817px var(--stars),
        468px 673px var(--stars), 1142px 1375px var(--stars),
        1228px 443px var(--stars), 1734px 552px var(--stars),
        20px 1041px var(--stars), 1783px 334px var(--stars),
        98px 1237px var(--stars), 1356px 1940px var(--stars),
        853px 1779px var(--stars), 1910px 560px var(--stars),
        1174px 1656px var(--stars), 110px 1724px var(--stars),
        542px 1771px var(--stars), 1758px 1931px var(--stars),
        1463px 1401px var(--stars), 1155px 84px var(--stars),
        1504px 835px var(--stars), 750px 322px var(--stars),
        407px 1900px var(--stars), 1600px 1141px var(--stars),
        657px 886px var(--stars), 526px 714px var(--stars),
        18px 836px var(--stars), 1546px 1548px var(--stars),
        22px 469px var(--stars), 594px 1466px var(--stars),
        1160px 1078px var(--stars), 627px 1055px var(--stars),
        195px 699px var(--stars), 1099px 684px var(--stars),
        530px 551px var(--stars), 1160px 1325px var(--stars),
        894px 727px var(--stars), 1157px 98px var(--stars),
        136px 1483px var(--stars), 1875px 1975px var(--stars),
        1803px 566px var(--stars), 318px 1073px var(--stars),
        1866px 1656px var(--stars), 543px 414px var(--stars),
        719px 474px var(--stars), 1115px 738px var(--stars),
        353px 875px var(--stars), 184px 1938px var(--stars),
        1854px 1534px var(--stars), 420px 1698px var(--stars),
        1480px 1550px var(--stars), 522px 203px var(--stars),
        1897px 1904px var(--stars), 975px 1708px var(--stars),
        1774px 602px var(--stars), 1908px 274px var(--stars),
        61px 715px var(--stars), 983px 1156px var(--stars),
        326px 1013px var(--stars), 641px 290px var(--stars),
        1522px 120px var(--stars), 405px 1637px var(--stars),
        1021px 1099px var(--stars), 631px 1145px var(--stars),
        982px 1967px var(--stars), 200px 651px var(--stars),
        795px 351px var(--stars), 790px 1082px var(--stars),
        144px 1572px var(--stars), 1542px 901px var(--stars),
        158px 1524px var(--stars), 849px 1843px var(--stars),
        1807px 203px var(--stars), 1747px 45px var(--stars),
        1603px 1738px var(--stars), 617px 1966px var(--stars),
        342px 748px var(--stars), 1779px 1173px var(--stars),
        1428px 152px var(--stars), 589px 1998px var(--stars),
        1940px 1838px var(--stars), 115px 272px var(--stars),
        1217px 1395px var(--stars), 1402px 1491px var(--stars),
        1833px 1814px var(--stars), 243px 966px var(--stars),
        319px 578px var(--stars), 813px 364px var(--stars),
        669px 882px var(--stars), 551px 134px var(--stars),
        1819px 920px var(--stars), 740px 1826px var(--stars),
        1021px 952px var(--stars), 1575px 453px var(--stars),
        324px 419px var(--stars), 929px 417px var(--stars),
        885px 1112px var(--stars), 503px 187px var(--stars),
        1908px 362px var(--stars), 1063px 1601px var(--stars),
        169px 1792px var(--stars), 789px 963px var(--stars),
        1697px 948px var(--stars), 1761px 1810px var(--stars),
        1844px 1591px var(--stars), 1709px 949px var(--stars),
        1402px 1396px var(--stars), 1037px 225px var(--stars),
        1832px 518px var(--stars), 1728px 1782px var(--stars),
        194px 1421px var(--stars), 1395px 742px var(--stars),
        1478px 1325px var(--stars), 40px 593px var(--stars),
        1732px 117px var(--stars), 51px 158px var(--stars),
        1598px 1672px var(--stars), 701px 849px var(--stars),
        1403px 1979px var(--stars), 145px 1414px var(--stars),
        550px 906px var(--stars), 1366px 460px var(--stars),
        142px 1379px var(--stars), 34px 1864px var(--stars),
        1346px 308px var(--stars), 293px 998px var(--stars),
        21px 1868px var(--stars), 540px 1033px var(--stars),
        60px 746px var(--stars), 1602px 1476px var(--stars),
        180px 804px var(--stars), 345px 1982px var(--stars),
        1439px 640px var(--stars), 939px 1834px var(--stars),
        20px 432px var(--stars), 492px 1549px var(--stars),
        109px 1579px var(--stars), 1796px 1403px var(--stars),
        1079px 519px var(--stars), 1664px 389px var(--stars),
        1627px 1061px var(--stars), 823px 419px var(--stars),
        1399px 1882px var(--stars), 1906px 344px var(--stars),
        1189px 848px var(--stars), 117px 882px var(--stars),
        1262px 33px var(--stars), 1048px 434px var(--stars),
        1208px 1309px var(--stars), 1616px 408px var(--stars),
        1833px 853px var(--stars), 1433px 1656px var(--stars),
        811px 1861px var(--stars), 439px 1672px var(--stars),
        1105px 248px var(--stars), 328px 1652px var(--stars),
        13px 1658px var(--stars), 685px 987px var(--stars),
        985px 403px var(--stars), 1664px 1206px var(--stars),
        1993px 1925px var(--stars), 440px 917px var(--stars),
        1835px 319px var(--stars), 1404px 1907px var(--stars),
        624px 1443px var(--stars), 843px 954px var(--stars),
        478px 1567px var(--stars), 895px 1602px var(--stars),
        1231px 871px var(--stars), 1267px 1646px var(--stars),
        475px 334px var(--stars), 784px 796px var(--stars),
        1294px 199px var(--stars), 109px 702px var(--stars),
        1978px 362px var(--stars), 291px 940px var(--stars),
        971px 1343px var(--stars), 74px 719px var(--stars),
        36px 715px var(--stars), 1007px 1423px var(--stars),
        860px 314px var(--stars), 631px 177px var(--stars),
        1900px 1590px var(--stars), 1239px 1348px var(--stars),
        1346px 1270px var(--stars), 1934px 1475px var(--stars),
        1553px 559px var(--stars), 588px 1969px var(--stars),
        670px 1269px var(--stars), 1484px 376px var(--stars),
        20px 1424px var(--stars), 1396px 8px var(--stars),
        969px 244px var(--stars), 1807px 538px var(--stars),
        1873px 891px var(--stars), 636px 1142px var(--stars),
        1474px 1562px var(--stars), 763px 350px var(--stars),
        663px 700px var(--stars), 500px 1469px var(--stars),
        1302px 722px var(--stars), 181px 291px var(--stars),
        266px 893px var(--stars), 1403px 654px var(--stars),
        492px 460px var(--stars), 1503px 1369px var(--stars),
        23px 1662px var(--stars), 349px 333px var(--stars),
        1435px 1017px var(--stars), 1441px 705px var(--stars),
        1708px 1446px var(--stars), 1041px 911px var(--stars),
        1063px 780px var(--stars), 1158px 1356px var(--stars),
        767px 1454px var(--stars), 1912px 797px var(--stars),
        1731px 1759px var(--stars), 1378px 1390px var(--stars),
        1815px 1364px var(--stars), 960px 270px var(--stars),
        1343px 427px var(--stars), 275px 203px var(--stars),
        1319px 1092px var(--stars), 1455px 770px var(--stars),
        283px 1503px var(--stars), 1505px 901px var(--stars),
        1738px 1561px var(--stars), 1526px 1935px var(--stars),
        1757px 669px var(--stars), 1640px 620px var(--stars),
        1750px 722px var(--stars), 748px 66px var(--stars),
        1149px 540px var(--stars), 159px 953px var(--stars),
        200px 1426px var(--stars), 515px 1110px var(--stars),
        1552px 737px var(--stars), 1094px 1459px var(--stars),
        778px 799px var(--stars), 1031px 523px var(--stars),
        743px 1825px var(--stars), 1100px 882px var(--stars),
        1088px 1836px var(--stars), 255px 599px var(--stars),
        67px 1361px var(--stars), 247px 1721px var(--stars),
        1722px 346px var(--stars), 1822px 155px var(--stars),
        452px 1973px var(--stars), 415px 1960px var(--stars),
        1109px 57px var(--stars), 273px 1392px var(--stars),
        404px 1071px var(--stars), 1212px 353px var(--stars),
        370px 460px var(--stars), 795px 1523px var(--stars),
        1932px 340px var(--stars), 51px 1473px var(--stars),
        1268px 364px var(--stars), 1512px 1862px var(--stars),
        1678px 1801px var(--stars), 1796px 579px var(--stars),
        254px 251px var(--stars), 1466px 1717px var(--stars),
        893px 379px var(--stars), 1153px 923px var(--stars),
        913px 1808px var(--stars), 791px 789px var(--stars),
        417px 1924px var(--stars), 1336px 1599px var(--stars),
        1695px 908px var(--stars), 1120px 114px var(--stars),
        493px 1949px var(--stars), 68px 1905px var(--stars),
        969px 481px var(--stars), 1420px 1095px var(--stars),
        800px 1117px var(--stars), 390px 234px var(--stars),
        356px 1644px var(--stars), 1098px 1486px var(--stars),
        1360px 521px var(--stars), 149px 1198px var(--stars),
        354px 747px var(--stars), 1749px 487px var(--stars),
        470px 76px var(--stars), 1672px 289px var(--stars),
        1731px 545px var(--stars), 1547px 1590px var(--stars),
        498px 692px var(--stars), 398px 1592px var(--stars),
        1846px 1237px var(--stars), 1537px 1474px var(--stars),
        1726px 1374px var(--stars), 1922px 858px var(--stars),
        376px 321px var(--stars), 985px 227px var(--stars),
        234px 1421px var(--stars), 760px 745px var(--stars),
        1990px 1132px var(--stars), 1560px 1597px var(--stars),
        338px 1310px var(--stars), 1924px 1664px var(--stars),
        547px 1747px var(--stars), 1639px 1282px var(--stars),
        1202px 337px var(--stars), 1985px 779px var(--stars),
        737px 456px var(--stars), 89px 501px var(--stars),
        963px 792px var(--stars), 655px 1447px var(--stars),
        1492px 1994px var(--stars), 1171px 254px var(--stars),
        892px 827px var(--stars), 1735px 442px var(--stars),
        1474px 1187px var(--stars), 846px 1518px var(--stars),
        557px 1805px var(--stars), 738px 945px var(--stars),
        795px 68px var(--stars), 663px 1956px var(--stars),
        1607px 290px var(--stars), 1524px 15px var(--stars),
        1097px 1911px var(--stars), 157px 1939px var(--stars),
        935px 1065px var(--stars), 1809px 1708px var(--stars),
        164px 1157px var(--stars), 83px 855px var(--stars),
        625px 501px var(--stars), 814px 398px var(--stars),
        552px 695px var(--stars), 597px 1546px var(--stars),
        1237px 1417px var(--stars), 628px 284px var(--stars),
        866px 767px var(--stars), 1403px 1394px var(--stars),
        765px 1563px var(--stars), 1648px 109px var(--stars),
        1205px 1659px var(--stars), 921px 1313px var(--stars),
        1319px 243px var(--stars), 18px 125px var(--stars),
        7px 777px var(--stars), 181px 418px var(--stars),
        1062px 1892px var(--stars), 382px 106px var(--stars),
        994px 751px var(--stars), 964px 234px var(--stars),
        40px 118px var(--stars), 278px 706px var(--stars),
        1540px 1978px var(--stars), 425px 1661px var(--stars),
        1050px 321px var(--stars), 735px 1729px var(--stars),
        1438px 260px var(--stars), 1229px 1109px var(--stars),
        186px 1041px var(--stars), 244px 1184px var(--stars),
        392px 1472px var(--stars), 670px 1249px var(--stars),
        1260px 1443px var(--stars), 1977px 1511px var(--stars),
        1240px 773px var(--stars), 303px 513px var(--stars),
        63px 1530px var(--stars), 610px 792px var(--stars),
        1987px 1647px var(--stars), 676px 1597px var(--stars),
        1740px 1244px var(--stars), 816px 1661px var(--stars),
        351px 802px var(--stars), 252px 1082px var(--stars),
        31px 365px var(--stars), 1453px 984px var(--stars),
        667px 1233px var(--stars), 1247px 1800px var(--stars),
        839px 270px var(--stars), 775px 913px var(--stars),
        1966px 1398px var(--stars), 499px 813px var(--stars),
        922px 1982px var(--stars), 1409px 1902px var(--stars),
        1499px 1766px var(--stars), 721px 899px var(--stars),
        788px 807px var(--stars), 989px 1355px var(--stars),
        1248px 1274px var(--stars), 849px 1091px var(--stars),
        1799px 1036px var(--stars), 1486px 700px var(--stars),
        170px 1989px var(--stars), 1275px 799px var(--stars),
        772px 2000px var(--stars), 1642px 362px var(--stars),
        216px 940px var(--stars), 1893px 281px var(--stars),
        1944px 1298px var(--stars), 1294px 400px var(--stars),
        1523px 441px var(--stars), 1829px 340px var(--stars),
        468px 170px var(--stars), 1099px 967px var(--stars),
        1331px 665px var(--stars), 1174px 1553px var(--stars),
        1567px 325px var(--stars), 1028px 1399px var(--stars),
        781px 1451px var(--stars), 1912px 1954px var(--stars),
        874px 873px var(--stars), 1298px 1722px var(--stars),
        1879px 706px var(--stars), 57px 1221px var(--stars),
        1116px 1432px var(--stars), 48px 811px var(--stars),
        101px 916px var(--stars), 677px 304px var(--stars),
        1203px 639px var(--stars), 1391px 199px var(--stars),
        1895px 1988px var(--stars), 1462px 1023px var(--stars),
        1216px 1751px var(--stars), 1261px 663px var(--stars),
        1290px 1119px var(--stars), 137px 1793px var(--stars),
        1052px 1470px var(--stars), 1561px 226px var(--stars),
        1156px 402px var(--stars), 709px 693px var(--stars),
        1040px 1911px var(--stars), 1624px 1115px var(--stars),
        551px 475px var(--stars), 416px 1090px var(--stars),
        1183px 451px var(--stars), 58px 765px var(--stars),
        743px 1016px var(--stars), 198px 369px var(--stars),
        1645px 1503px var(--stars), 997px 22px var(--stars),
        1447px 1323px var(--stars), 379px 883px var(--stars),
        1171px 1195px var(--stars), 919px 133px var(--stars),
        1400px 517px var(--stars), 725px 804px var(--stars),
        1600px 699px var(--stars), 357px 581px var(--stars),
        266px 1713px var(--stars), 848px 1749px var(--stars),
        1963px 1045px var(--stars), 119px 1136px var(--stars);
}

#stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1117px 1306px var(--stars), 1078px 1783px var(--stars),
        1179px 1085px var(--stars), 1145px 920px var(--stars),
        422px 1233px var(--stars), 387px 98px var(--stars),
        1153px 637px var(--stars), 1084px 782px var(--stars),
        476px 453px var(--stars), 926px 1306px var(--stars),
        60px 1086px var(--stars), 753px 1575px var(--stars),
        272px 1684px var(--stars), 1285px 750px var(--stars),
        1416px 1327px var(--stars), 1931px 473px var(--stars),
        736px 1395px var(--stars), 1816px 763px var(--stars),
        438px 879px var(--stars), 665px 1902px var(--stars),
        1341px 677px var(--stars), 1404px 1073px var(--stars),
        100px 597px var(--stars), 357px 1689px var(--stars),
        1044px 1342px var(--stars), 1954px 502px var(--stars),
        1192px 1308px var(--stars), 540px 1239px var(--stars),
        1360px 552px var(--stars), 89px 752px var(--stars),
        659px 1253px var(--stars), 62px 517px var(--stars),
        1375px 1705px var(--stars), 1343px 1511px var(--stars),
        1659px 1922px var(--stars), 1560px 289px var(--stars),
        1362px 1799px var(--stars), 1886px 1480px var(--stars),
        1718px 1885px var(--stars), 824px 738px var(--stars),
        1060px 1370px var(--stars), 1781px 1171px var(--stars),
        255px 273px var(--stars), 1197px 120px var(--stars),
        213px 7px var(--stars), 1226px 1920px var(--stars),
        1844px 207px var(--stars), 1675px 970px var(--stars),
        1435px 1283px var(--stars), 37px 353px var(--stars),
        59px 417px var(--stars), 921px 1602px var(--stars),
        1549px 1490px var(--stars), 638px 1845px var(--stars),
        1328px 198px var(--stars), 1050px 1149px var(--stars),
        1884px 711px var(--stars), 333px 263px var(--stars),
        342px 1508px var(--stars), 1388px 1810px var(--stars),
        1377px 1558px var(--stars), 890px 487px var(--stars),
        1081px 759px var(--stars), 890px 1515px var(--stars),
        911px 1284px var(--stars), 335px 735px var(--stars),
        1140px 549px var(--stars), 1239px 1064px var(--stars),
        226px 71px var(--stars), 1100px 1278px var(--stars),
        1851px 1805px var(--stars), 1370px 1999px var(--stars),
        1008px 1122px var(--stars), 785px 813px var(--stars),
        1358px 601px var(--stars), 1833px 1305px var(--stars),
        1768px 1304px var(--stars), 1303px 532px var(--stars),
        860px 598px var(--stars), 1329px 593px var(--stars),
        1038px 1088px var(--stars), 408px 405px var(--stars),
        965px 82px var(--stars), 1483px 1438px var(--stars),
        310px 1479px var(--stars), 1786px 1500px var(--stars),
        1866px 852px var(--stars), 18px 1757px var(--stars),
        1473px 1004px var(--stars), 1542px 1933px var(--stars),
        633px 1970px var(--stars), 1334px 1713px var(--stars),
        175px 28px var(--stars), 592px 894px var(--stars),
        121px 1162px var(--stars), 1601px 1567px var(--stars),
        1095px 657px var(--stars), 640px 1233px var(--stars),
        1073px 1255px var(--stars), 840px 1087px var(--stars),
        718px 250px var(--stars), 967px 709px var(--stars),
        731px 239px var(--stars), 1623px 593px var(--stars),
        1058px 1820px var(--stars), 516px 1898px var(--stars),
        666px 12px var(--stars), 1997px 1382px var(--stars),
        112px 1690px var(--stars), 687px 1309px var(--stars),
        63px 539px var(--stars), 185px 1897px var(--stars),
        1055px 1691px var(--stars), 435px 1517px var(--stars),
        1175px 1119px var(--stars), 1721px 133px var(--stars),
        1212px 47px var(--stars), 166px 18px var(--stars),
        1416px 1652px var(--stars), 1409px 1745px var(--stars),
        1357px 1232px var(--stars), 1677px 1998px var(--stars),
        448px 1415px var(--stars), 705px 1736px var(--stars),
        1031px 1466px var(--stars), 543px 1651px var(--stars),
        1592px 1888px var(--stars), 1749px 1175px var(--stars),
        639px 1114px var(--stars), 1591px 508px var(--stars),
        759px 1244px var(--stars), 824px 380px var(--stars),
        942px 955px var(--stars), 723px 732px var(--stars),
        113px 1369px var(--stars), 203px 1739px var(--stars),
        868px 733px var(--stars), 713px 971px var(--stars),
        341px 833px var(--stars), 762px 824px var(--stars),
        1359px 310px var(--stars), 1858px 1349px var(--stars),
        1531px 692px var(--stars), 1075px 1512px var(--stars),
        1677px 142px var(--stars), 1912px 1478px var(--stars),
        1810px 1078px var(--stars), 426px 844px var(--stars),
        1426px 588px var(--stars), 1909px 654px var(--stars),
        1107px 295px var(--stars), 1351px 527px var(--stars),
        1393px 599px var(--stars), 1379px 1068px var(--stars),
        228px 1846px var(--stars), 1271px 374px var(--stars),
        1348px 612px var(--stars), 7px 1301px var(--stars),
        1501px 1782px var(--stars), 1795px 423px var(--stars),
        1475px 1918px var(--stars), 1328px 1861px var(--stars),
        1624px 51px var(--stars), 1791px 672px var(--stars),
        1594px 1467px var(--stars), 1655px 1603px var(--stars),
        919px 850px var(--stars), 523px 609px var(--stars),
        1196px 207px var(--stars), 753px 410px var(--stars),
        686px 1097px var(--stars), 1570px 133px var(--stars),
        1996px 1137px var(--stars), 361px 116px var(--stars),
        1015px 462px var(--stars), 76px 1143px var(--stars),
        491px 1818px var(--stars), 1563px 795px var(--stars),
        982px 1721px var(--stars), 831px 1204px var(--stars),
        1737px 589px var(--stars), 861px 1579px var(--stars),
        1666px 130px var(--stars), 698px 1799px var(--stars),
        726px 1519px var(--stars), 109px 1208px var(--stars),
        1184px 1057px var(--stars), 835px 451px var(--stars),
        896px 594px var(--stars), 35px 893px var(--stars),
        895px 542px var(--stars), 706px 225px var(--stars),
        56px 1040px var(--stars), 1954px 108px var(--stars),
        1439px 1423px var(--stars), 26px 1881px var(--stars),
        802px 1564px var(--stars), 273px 708px var(--stars),
        40px 31px var(--stars), 859px 108px var(--stars);
    animation: animStar 100s linear infinite;
}
#stars2:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1117px 1306px var(--stars), 1078px 1783px var(--stars),
        1179px 1085px var(--stars), 1145px 920px var(--stars),
        422px 1233px var(--stars), 387px 98px var(--stars),
        1153px 637px var(--stars), 1084px 782px var(--stars),
        476px 453px var(--stars), 926px 1306px var(--stars),
        60px 1086px var(--stars), 753px 1575px var(--stars),
        272px 1684px var(--stars), 1285px 750px var(--stars),
        1416px 1327px var(--stars), 1931px 473px var(--stars),
        736px 1395px var(--stars), 1816px 763px var(--stars),
        438px 879px var(--stars), 665px 1902px var(--stars),
        1341px 677px var(--stars), 1404px 1073px var(--stars),
        100px 597px var(--stars), 357px 1689px var(--stars),
        1044px 1342px var(--stars), 1954px 502px var(--stars),
        1192px 1308px var(--stars), 540px 1239px var(--stars),
        1360px 552px var(--stars), 89px 752px var(--stars),
        659px 1253px var(--stars), 62px 517px var(--stars),
        1375px 1705px var(--stars), 1343px 1511px var(--stars),
        1659px 1922px var(--stars), 1560px 289px var(--stars),
        1362px 1799px var(--stars), 1886px 1480px var(--stars),
        1718px 1885px var(--stars), 824px 738px var(--stars),
        1060px 1370px var(--stars), 1781px 1171px var(--stars),
        255px 273px var(--stars), 1197px 120px var(--stars),
        213px 7px var(--stars), 1226px 1920px var(--stars),
        1844px 207px var(--stars), 1675px 970px var(--stars),
        1435px 1283px var(--stars), 37px 353px var(--stars),
        59px 417px var(--stars), 921px 1602px var(--stars),
        1549px 1490px var(--stars), 638px 1845px var(--stars),
        1328px 198px var(--stars), 1050px 1149px var(--stars),
        1884px 711px var(--stars), 333px 263px var(--stars),
        342px 1508px var(--stars), 1388px 1810px var(--stars),
        1377px 1558px var(--stars), 890px 487px var(--stars),
        1081px 759px var(--stars), 890px 1515px var(--stars),
        911px 1284px var(--stars), 335px 735px var(--stars),
        1140px 549px var(--stars), 1239px 1064px var(--stars),
        226px 71px var(--stars), 1100px 1278px var(--stars),
        1851px 1805px var(--stars), 1370px 1999px var(--stars),
        1008px 1122px var(--stars), 785px 813px var(--stars),
        1358px 601px var(--stars), 1833px 1305px var(--stars),
        1768px 1304px var(--stars), 1303px 532px var(--stars),
        860px 598px var(--stars), 1329px 593px var(--stars),
        1038px 1088px var(--stars), 408px 405px var(--stars),
        965px 82px var(--stars), 1483px 1438px var(--stars),
        310px 1479px var(--stars), 1786px 1500px var(--stars),
        1866px 852px var(--stars), 18px 1757px var(--stars),
        1473px 1004px var(--stars), 1542px 1933px var(--stars),
        633px 1970px var(--stars), 1334px 1713px var(--stars),
        175px 28px var(--stars), 592px 894px var(--stars),
        121px 1162px var(--stars), 1601px 1567px var(--stars),
        1095px 657px var(--stars), 640px 1233px var(--stars),
        1073px 1255px var(--stars), 840px 1087px var(--stars),
        718px 250px var(--stars), 967px 709px var(--stars),
        731px 239px var(--stars), 1623px 593px var(--stars),
        1058px 1820px var(--stars), 516px 1898px var(--stars),
        666px 12px var(--stars), 1997px 1382px var(--stars),
        112px 1690px var(--stars), 687px 1309px var(--stars),
        63px 539px var(--stars), 185px 1897px var(--stars),
        1055px 1691px var(--stars), 435px 1517px var(--stars),
        1175px 1119px var(--stars), 1721px 133px var(--stars),
        1212px 47px var(--stars), 166px 18px var(--stars),
        1416px 1652px var(--stars), 1409px 1745px var(--stars),
        1357px 1232px var(--stars), 1677px 1998px var(--stars),
        448px 1415px var(--stars), 705px 1736px var(--stars),
        1031px 1466px var(--stars), 543px 1651px var(--stars),
        1592px 1888px var(--stars), 1749px 1175px var(--stars),
        639px 1114px var(--stars), 1591px 508px var(--stars),
        759px 1244px var(--stars), 824px 380px var(--stars),
        942px 955px var(--stars), 723px 732px var(--stars),
        113px 1369px var(--stars), 203px 1739px var(--stars),
        868px 733px var(--stars), 713px 971px var(--stars),
        341px 833px var(--stars), 762px 824px var(--stars),
        1359px 310px var(--stars), 1858px 1349px var(--stars),
        1531px 692px var(--stars), 1075px 1512px var(--stars),
        1677px 142px var(--stars), 1912px 1478px var(--stars),
        1810px 1078px var(--stars), 426px 844px var(--stars),
        1426px 588px var(--stars), 1909px 654px var(--stars),
        1107px 295px var(--stars), 1351px 527px var(--stars),
        1393px 599px var(--stars), 1379px 1068px var(--stars),
        228px 1846px var(--stars), 1271px 374px var(--stars),
        1348px 612px var(--stars), 7px 1301px var(--stars),
        1501px 1782px var(--stars), 1795px 423px var(--stars),
        1475px 1918px var(--stars), 1328px 1861px var(--stars),
        1624px 51px var(--stars), 1791px 672px var(--stars),
        1594px 1467px var(--stars), 1655px 1603px var(--stars),
        919px 850px var(--stars), 523px 609px var(--stars),
        1196px 207px var(--stars), 753px 410px var(--stars),
        686px 1097px var(--stars), 1570px 133px var(--stars),
        1996px 1137px var(--stars), 361px 116px var(--stars),
        1015px 462px var(--stars), 76px 1143px var(--stars),
        491px 1818px var(--stars), 1563px 795px var(--stars),
        982px 1721px var(--stars), 831px 1204px var(--stars),
        1737px 589px var(--stars), 861px 1579px var(--stars),
        1666px 130px var(--stars), 698px 1799px var(--stars),
        726px 1519px var(--stars), 109px 1208px var(--stars),
        1184px 1057px var(--stars), 835px 451px var(--stars),
        896px 594px var(--stars), 35px 893px var(--stars),
        895px 542px var(--stars), 706px 225px var(--stars),
        56px 1040px var(--stars), 1954px 108px var(--stars),
        1439px 1423px var(--stars), 26px 1881px var(--stars),
        802px 1564px var(--stars), 273px 708px var(--stars),
        40px 31px var(--stars), 859px 108px var(--stars);
}
#stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 940px 1360px var(--stars), 1071px 539px var(--stars),
        1710px 1414px var(--stars), 836px 299px var(--stars),
        1944px 1420px var(--stars), 253px 1449px var(--stars),
        1257px 1250px var(--stars), 1588px 1830px var(--stars),
        1077px 1204px var(--stars), 273px 1081px var(--stars),
        1993px 766px var(--stars), 1808px 479px var(--stars),
        917px 263px var(--stars), 663px 1820px var(--stars),
        342px 1988px var(--stars), 727px 1250px var(--stars),
        636px 1666px var(--stars), 692px 1112px var(--stars),
        248px 1211px var(--stars), 1422px 1121px var(--stars),
        881px 46px var(--stars), 1531px 1977px var(--stars),
        1643px 1023px var(--stars), 684px 1071px var(--stars),
        1142px 1873px var(--stars), 292px 1313px var(--stars),
        256px 1237px var(--stars), 89px 912px var(--stars),
        964px 1783px var(--stars), 877px 760px var(--stars),
        1641px 1474px var(--stars), 1492px 24px var(--stars),
        1776px 1642px var(--stars), 183px 602px var(--stars),
        1998px 62px var(--stars), 1560px 367px var(--stars),
        1333px 995px var(--stars), 704px 1815px var(--stars),
        1809px 712px var(--stars), 1503px 288px var(--stars),
        630px 556px var(--stars), 1715px 125px var(--stars),
        353px 1878px var(--stars), 975px 333px var(--stars),
        1740px 1409px var(--stars), 1341px 1871px var(--stars),
        1279px 1064px var(--stars), 169px 874px var(--stars),
        161px 528px var(--stars), 1671px 1669px var(--stars),
        169px 632px var(--stars), 547px 1724px var(--stars),
        1904px 110px var(--stars), 679px 1670px var(--stars),
        196px 123px var(--stars), 786px 871px var(--stars),
        1840px 324px var(--stars), 356px 967px var(--stars),
        61px 549px var(--stars), 99px 677px var(--stars),
        1719px 87px var(--stars), 1713px 1990px var(--stars),
        1717px 1358px var(--stars), 108px 1187px var(--stars),
        51px 869px var(--stars), 1461px 902px var(--stars),
        1034px 891px var(--stars), 962px 1881px var(--stars),
        1723px 595px var(--stars), 479px 901px var(--stars),
        1546px 1823px var(--stars), 285px 1208px var(--stars),
        1056px 347px var(--stars), 261px 988px var(--stars),
        466px 990px var(--stars), 1657px 648px var(--stars),
        1249px 933px var(--stars), 1552px 1555px var(--stars),
        147px 62px var(--stars), 292px 1157px var(--stars),
        1816px 423px var(--stars), 1714px 757px var(--stars),
        1036px 961px var(--stars), 1955px 710px var(--stars),
        1842px 516px var(--stars), 479px 1870px var(--stars),
        1579px 1445px var(--stars), 1225px 1309px var(--stars),
        1965px 566px var(--stars), 1575px 1072px var(--stars),
        923px 329px var(--stars), 651px 1514px var(--stars),
        865px 1100px var(--stars), 782px 1873px var(--stars),
        115px 299px var(--stars), 14px 1668px var(--stars),
        1666px 1817px var(--stars), 1096px 1068px var(--stars),
        1462px 742px var(--stars), 1384px 1750px var(--stars);
    animation: animStar 150s linear infinite;
}
#stars3:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 940px 1360px var(--stars), 1071px 539px var(--stars),
        1710px 1414px var(--stars), 836px 299px var(--stars),
        1944px 1420px var(--stars), 253px 1449px var(--stars),
        1257px 1250px var(--stars), 1588px 1830px var(--stars),
        1077px 1204px var(--stars), 273px 1081px var(--stars),
        1993px 766px var(--stars), 1808px 479px var(--stars),
        917px 263px var(--stars), 663px 1820px var(--stars),
        342px 1988px var(--stars), 727px 1250px var(--stars),
        636px 1666px var(--stars), 692px 1112px var(--stars),
        248px 1211px var(--stars), 1422px 1121px var(--stars),
        881px 46px var(--stars), 1531px 1977px var(--stars),
        1643px 1023px var(--stars), 684px 1071px var(--stars),
        1142px 1873px var(--stars), 292px 1313px var(--stars),
        256px 1237px var(--stars), 89px 912px var(--stars),
        964px 1783px var(--stars), 877px 760px var(--stars),
        1641px 1474px var(--stars), 1492px 24px var(--stars),
        1776px 1642px var(--stars), 183px 602px var(--stars),
        1998px 62px var(--stars), 1560px 367px var(--stars),
        1333px 995px var(--stars), 704px 1815px var(--stars),
        1809px 712px var(--stars), 1503px 288px var(--stars),
        630px 556px var(--stars), 1715px 125px var(--stars),
        353px 1878px var(--stars), 975px 333px var(--stars),
        1740px 1409px var(--stars), 1341px 1871px var(--stars),
        1279px 1064px var(--stars), 169px 874px var(--stars),
        161px 528px var(--stars), 1671px 1669px var(--stars),
        169px 632px var(--stars), 547px 1724px var(--stars),
        1904px 110px var(--stars), 679px 1670px var(--stars),
        196px 123px var(--stars), 786px 871px var(--stars),
        1840px 324px var(--stars), 356px 967px var(--stars),
        61px 549px var(--stars), 99px 677px var(--stars),
        1719px 87px var(--stars), 1713px 1990px var(--stars),
        1717px 1358px var(--stars), 108px 1187px var(--stars),
        51px 869px var(--stars), 1461px 902px var(--stars),
        1034px 891px var(--stars), 962px 1881px var(--stars),
        1723px 595px var(--stars), 479px 901px var(--stars),
        1546px 1823px var(--stars), 285px 1208px var(--stars),
        1056px 347px var(--stars), 261px 988px var(--stars),
        466px 990px var(--stars), 1657px 648px var(--stars),
        1249px 933px var(--stars), 1552px 1555px var(--stars),
        147px 62px var(--stars), 292px 1157px var(--stars),
        1816px 423px var(--stars), 1714px 757px var(--stars),
        1036px 961px var(--stars), 1955px 710px var(--stars),
        1842px 516px var(--stars), 479px 1870px var(--stars),
        1579px 1445px var(--stars), 1225px 1309px var(--stars),
        1965px 566px var(--stars), 1575px 1072px var(--stars),
        923px 329px var(--stars), 651px 1514px var(--stars),
        865px 1100px var(--stars), 782px 1873px var(--stars),
        115px 299px var(--stars), 14px 1668px var(--stars),
        1666px 1817px var(--stars), 1096px 1068px var(--stars),
        1462px 742px var(--stars), 1384px 1750px var(--stars);
}

#stars4 {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 233px 1976px var(--stars), 1196px 1119px var(--stars),
        646px 740px var(--stars), 335px 645px var(--stars),
        1119px 1452px var(--stars), 176px 1870px var(--stars),
        639px 1711px var(--stars), 647px 1388px var(--stars),
        1516px 1108px var(--stars), 464px 66px var(--stars),
        331px 344px var(--stars), 772px 1189px var(--stars),
        1516px 1850px var(--stars), 1500px 1463px var(--stars),
        1275px 876px var(--stars), 1107px 645px var(--stars),
        977px 478px var(--stars), 583px 1179px var(--stars),
        284px 395px var(--stars), 1220px 461px var(--stars),
        1160px 249px var(--stars), 196px 865px var(--stars),
        670px 1915px var(--stars), 1449px 382px var(--stars),
        1191px 546px var(--stars), 1329px 605px var(--stars),
        1945px 458px var(--stars), 995px 749px var(--stars),
        1495px 861px var(--stars), 708px 1731px var(--stars),
        348px 653px var(--stars), 548px 1298px var(--stars),
        1606px 990px var(--stars), 1049px 1204px var(--stars),
        253px 1501px var(--stars), 1154px 166px var(--stars),
        1087px 104px var(--stars), 1034px 1161px var(--stars),
        1681px 462px var(--stars), 577px 1897px var(--stars),
        193px 1901px var(--stars), 1701px 1755px var(--stars),
        864px 1297px var(--stars), 800px 1289px var(--stars),
        676px 28px var(--stars), 185px 1341px var(--stars),
        379px 1151px var(--stars), 1224px 1725px var(--stars),
        280px 541px var(--stars), 473px 1196px var(--stars),
        921px 1628px var(--stars), 969px 432px var(--stars),
        1475px 758px var(--stars), 1195px 993px var(--stars),
        876px 1840px var(--stars), 1274px 1689px var(--stars),
        1977px 1101px var(--stars), 837px 527px var(--stars),
        1785px 1610px var(--stars), 1650px 1843px var(--stars),
        1127px 1508px var(--stars), 401px 1050px var(--stars),
        51px 1105px var(--stars), 545px 880px var(--stars),
        1786px 1672px var(--stars), 318px 260px var(--stars),
        568px 254px var(--stars), 1026px 1527px var(--stars),
        1242px 852px var(--stars), 1785px 982px var(--stars),
        1318px 1071px var(--stars), 398px 1061px var(--stars),
        1509px 257px var(--stars), 599px 928px var(--stars),
        1195px 1800px var(--stars), 1254px 906px var(--stars),
        141px 26px var(--stars), 1384px 1502px var(--stars),
        476px 767px var(--stars), 1973px 722px var(--stars),
        1339px 1031px var(--stars), 778px 818px var(--stars),
        213px 1320px var(--stars), 184px 221px var(--stars),
        983px 1911px var(--stars), 923px 1439px var(--stars),
        1936px 581px var(--stars), 1105px 625px var(--stars),
        325px 729px var(--stars), 1475px 204px var(--stars),
        1483px 1564px var(--stars), 1327px 1272px var(--stars),
        1187px 1944px var(--stars), 1945px 1471px var(--stars),
        116px 960px var(--stars), 1660px 1610px var(--stars),
        412px 1022px var(--stars), 1552px 1516px var(--stars),
        1517px 1892px var(--stars), 306px 829px var(--stars),
        1416px 462px var(--stars), 1575px 1460px var(--stars),
        424px 1500px var(--stars), 1530px 1169px var(--stars),
        1388px 1608px var(--stars), 185px 416px var(--stars),
        634px 1446px var(--stars), 767px 479px var(--stars),
        71px 426px var(--stars), 1937px 145px var(--stars),
        1955px 1312px var(--stars), 1811px 611px var(--stars),
        1145px 569px var(--stars), 1460px 676px var(--stars),
        131px 1858px var(--stars), 1557px 473px var(--stars),
        735px 130px var(--stars), 112px 1531px var(--stars),
        1312px 305px var(--stars), 409px 1032px var(--stars),
        149px 1964px var(--stars), 535px 1215px var(--stars),
        1382px 630px var(--stars), 1437px 1368px var(--stars),
        362px 1181px var(--stars), 388px 181px var(--stars),
        274px 1287px var(--stars), 1858px 1414px var(--stars),
        661px 1935px var(--stars), 675px 1205px var(--stars),
        1829px 1725px var(--stars), 1937px 1145px var(--stars),
        237px 908px var(--stars), 1059px 1185px var(--stars),
        824px 1248px var(--stars), 1167px 1730px var(--stars),
        180px 1961px var(--stars), 1663px 203px var(--stars),
        374px 221px var(--stars), 724px 1883px var(--stars),
        970px 1362px var(--stars), 832px 505px var(--stars),
        313px 233px var(--stars), 1909px 597px var(--stars),
        434px 201px var(--stars), 587px 995px var(--stars),
        1833px 623px var(--stars), 1464px 561px var(--stars),
        231px 593px var(--stars), 1558px 1433px var(--stars),
        1986px 1767px var(--stars), 1753px 1728px var(--stars),
        1153px 1623px var(--stars), 249px 229px var(--stars),
        1503px 1186px var(--stars), 1784px 137px var(--stars),
        841px 403px var(--stars), 1400px 354px var(--stars),
        197px 499px var(--stars), 1188px 681px var(--stars),
        158px 391px var(--stars), 443px 1099px var(--stars),
        723px 1445px var(--stars), 1408px 1235px var(--stars),
        1908px 195px var(--stars), 271px 891px var(--stars),
        469px 1693px var(--stars), 580px 11px var(--stars),
        1533px 70px var(--stars), 859px 761px var(--stars),
        1510px 1844px var(--stars), 421px 558px var(--stars),
        1132px 1453px var(--stars), 757px 1987px var(--stars),
        212px 293px var(--stars), 569px 323px var(--stars),
        1404px 1394px var(--stars), 252px 1386px var(--stars),
        1668px 1857px var(--stars), 123px 1684px var(--stars),
        105px 490px var(--stars), 1083px 1769px var(--stars),
        1071px 1953px var(--stars), 1271px 1159px var(--stars),
        699px 1491px var(--stars), 1744px 1997px var(--stars),
        1868px 1973px var(--stars), 1438px 1449px var(--stars),
        1222px 1921px var(--stars), 1328px 1210px var(--stars),
        438px 873px var(--stars), 809px 780px var(--stars),
        491px 1524px var(--stars), 447px 1830px var(--stars),
        927px 1936px var(--stars), 564px 691px var(--stars),
        1784px 1747px var(--stars), 1978px 1722px var(--stars),
        1599px 1480px var(--stars), 1276px 729px var(--stars),
        731px 1174px var(--stars), 1586px 1711px var(--stars),
        451px 1340px var(--stars), 1075px 1899px var(--stars),
        13px 575px var(--stars), 309px 1340px var(--stars),
        981px 183px var(--stars), 248px 1315px var(--stars),
        849px 80px var(--stars), 1754px 1540px var(--stars),
        73px 1432px var(--stars), 1208px 1828px var(--stars),
        65px 575px var(--stars), 1098px 730px var(--stars),
        127px 1358px var(--stars), 185px 19px var(--stars),
        1222px 1679px var(--stars), 1122px 315px var(--stars),
        1906px 452px var(--stars), 761px 284px var(--stars),
        813px 492px var(--stars), 1344px 843px var(--stars),
        118px 1834px var(--stars), 1620px 359px var(--stars),
        1755px 1246px var(--stars), 299px 1076px var(--stars),
        1746px 158px var(--stars), 6px 1635px var(--stars),
        143px 190px var(--stars), 101px 468px var(--stars),
        137px 971px var(--stars), 1221px 1929px var(--stars),
        1752px 650px var(--stars), 1635px 1761px var(--stars),
        1522px 833px var(--stars), 908px 153px var(--stars),
        1044px 350px var(--stars), 1151px 1940px var(--stars),
        822px 210px var(--stars), 1774px 310px var(--stars),
        796px 1447px var(--stars), 1069px 1903px var(--stars),
        217px 565px var(--stars), 662px 1370px var(--stars),
        1876px 1570px var(--stars), 847px 46px var(--stars),
        1042px 1689px var(--stars), 1584px 1434px var(--stars),
        1791px 908px var(--stars), 973px 908px var(--stars),
        793px 747px var(--stars), 122px 483px var(--stars),
        1137px 1374px var(--stars), 1757px 1791px var(--stars),
        513px 225px var(--stars), 63px 731px var(--stars),
        1179px 1926px var(--stars), 346px 18px var(--stars),
        589px 175px var(--stars), 87px 302px var(--stars),
        380px 1295px var(--stars), 450px 921px var(--stars),
        1667px 1973px var(--stars), 1495px 1373px var(--stars),
        1462px 1850px var(--stars), 540px 288px var(--stars),
        1208px 1051px var(--stars), 1554px 1095px var(--stars),
        1009px 1516px var(--stars), 181px 572px var(--stars),
        165px 387px var(--stars), 549px 1835px var(--stars),
        960px 16px var(--stars), 1360px 403px var(--stars),
        1251px 43px var(--stars), 1905px 1813px var(--stars),
        1106px 866px var(--stars), 1809px 277px var(--stars),
        1828px 1720px var(--stars), 295px 1610px var(--stars),
        523px 166px var(--stars), 1069px 692px var(--stars),
        1292px 217px var(--stars), 11px 1721px var(--stars),
        99px 1045px var(--stars), 51px 1584px var(--stars),
        1053px 266px var(--stars), 1287px 1235px var(--stars),
        747px 1722px var(--stars), 1542px 736px var(--stars),
        1256px 18px var(--stars), 102px 609px var(--stars),
        586px 1339px var(--stars), 1843px 1697px var(--stars),
        824px 1687px var(--stars), 1124px 882px var(--stars),
        395px 501px var(--stars), 1456px 672px var(--stars),
        1472px 1648px var(--stars), 1326px 1164px var(--stars),
        777px 1672px var(--stars), 81px 345px var(--stars),
        91px 386px var(--stars), 243px 411px var(--stars),
        1560px 90px var(--stars), 6px 1771px var(--stars),
        1601px 616px var(--stars), 1220px 1808px var(--stars),
        1160px 836px var(--stars), 246px 1777px var(--stars),
        456px 863px var(--stars), 97px 1138px var(--stars),
        1811px 942px var(--stars), 213px 414px var(--stars),
        891px 392px var(--stars), 1044px 927px var(--stars),
        1856px 216px var(--stars), 957px 347px var(--stars),
        1486px 406px var(--stars), 838px 912px var(--stars),
        803px 361px var(--stars), 564px 826px var(--stars),
        1597px 949px var(--stars), 1206px 289px var(--stars),
        33px 1035px var(--stars), 1762px 1377px var(--stars),
        789px 1815px var(--stars), 1594px 1342px var(--stars),
        1668px 880px var(--stars), 1539px 1581px var(--stars),
        1547px 53px var(--stars), 861px 1433px var(--stars),
        693px 1618px var(--stars), 1762px 782px var(--stars),
        1568px 682px var(--stars), 1126px 1762px var(--stars),
        1242px 134px var(--stars), 495px 959px var(--stars),
        1606px 219px var(--stars), 1878px 1415px var(--stars),
        1652px 797px var(--stars), 782px 1903px var(--stars),
        1774px 1133px var(--stars), 1430px 408px var(--stars),
        265px 394px var(--stars), 890px 336px var(--stars),
        1051px 311px var(--stars), 461px 1559px var(--stars),
        1931px 91px var(--stars), 1160px 380px var(--stars),
        1442px 1058px var(--stars), 1157px 364px var(--stars),
        586px 227px var(--stars), 1365px 715px var(--stars),
        1658px 1655px var(--stars), 1923px 1664px var(--stars),
        1023px 1844px var(--stars), 1939px 1367px var(--stars),
        1203px 1305px var(--stars), 359px 642px var(--stars),
        1056px 425px var(--stars), 787px 202px var(--stars),
        1609px 1850px var(--stars), 1964px 200px var(--stars),
        1537px 586px var(--stars), 1589px 903px var(--stars),
        1063px 1694px var(--stars), 760px 1185px var(--stars),
        597px 1396px var(--stars), 294px 452px var(--stars),
        433px 818px var(--stars), 199px 840px var(--stars),
        1332px 1937px var(--stars), 169px 1907px var(--stars),
        591px 834px var(--stars), 1716px 1032px var(--stars),
        45px 1879px var(--stars), 686px 1469px var(--stars),
        1520px 475px var(--stars), 1122px 859px var(--stars),
        973px 1541px var(--stars), 269px 477px var(--stars),
        1390px 716px var(--stars), 1791px 783px var(--stars),
        824px 2000px var(--stars), 1211px 1717px var(--stars),
        1008px 1587px var(--stars), 1422px 204px var(--stars),
        234px 556px var(--stars), 506px 550px var(--stars),
        942px 1670px var(--stars), 397px 853px var(--stars),
        599px 795px var(--stars), 762px 1926px var(--stars),
        1202px 1424px var(--stars), 135px 1316px var(--stars),
        1442px 1692px var(--stars), 977px 652px var(--stars),
        564px 1648px var(--stars), 997px 1474px var(--stars),
        67px 1366px var(--stars), 1860px 1451px var(--stars),
        1105px 772px var(--stars), 1886px 1396px var(--stars),
        1510px 658px var(--stars), 976px 1544px var(--stars),
        894px 543px var(--stars), 1098px 1189px var(--stars),
        690px 77px var(--stars), 770px 733px var(--stars),
        557px 1403px var(--stars), 1758px 1623px var(--stars),
        1341px 812px var(--stars), 699px 967px var(--stars),
        277px 866px var(--stars), 1526px 1828px var(--stars),
        8px 977px var(--stars), 1707px 952px var(--stars),
        12px 1900px var(--stars), 72px 921px var(--stars),
        496px 1067px var(--stars), 1288px 1749px var(--stars),
        273px 984px var(--stars), 1197px 1991px var(--stars),
        242px 789px var(--stars), 903px 1035px var(--stars),
        480px 1492px var(--stars), 102px 1331px var(--stars),
        738px 1343px var(--stars), 560px 1475px var(--stars),
        367px 846px var(--stars), 1420px 962px var(--stars),
        1976px 892px var(--stars), 1911px 1763px var(--stars),
        1639px 1002px var(--stars), 437px 1522px var(--stars),
        1906px 1025px var(--stars), 730px 1364px var(--stars),
        1127px 521px var(--stars), 1401px 1792px var(--stars),
        1954px 1066px var(--stars), 232px 250px var(--stars),
        1685px 660px var(--stars), 1011px 999px var(--stars),
        1970px 790px var(--stars), 750px 499px var(--stars),
        1738px 660px var(--stars), 1621px 1849px var(--stars),
        446px 52px var(--stars), 1055px 1396px var(--stars),
        1165px 1497px var(--stars), 1740px 1425px var(--stars),
        1012px 1920px var(--stars), 1258px 1560px var(--stars),
        1020px 1152px var(--stars), 362px 673px var(--stars),
        1065px 975px var(--stars), 582px 755px var(--stars),
        1271px 1479px var(--stars), 719px 548px var(--stars),
        1602px 879px var(--stars), 590px 499px var(--stars),
        721px 1412px var(--stars), 1180px 113px var(--stars),
        1801px 1961px var(--stars), 589px 941px var(--stars),
        883px 476px var(--stars), 214px 890px var(--stars),
        1028px 892px var(--stars), 1107px 1832px var(--stars),
        944px 361px var(--stars), 480px 1453px var(--stars),
        1466px 683px var(--stars), 981px 745px var(--stars),
        1968px 828px var(--stars), 657px 1830px var(--stars),
        11px 1338px var(--stars), 179px 730px var(--stars),
        1713px 197px var(--stars), 51px 955px var(--stars),
        1243px 319px var(--stars), 1175px 624px var(--stars),
        446px 46px var(--stars), 5px 1158px var(--stars),
        82px 1352px var(--stars), 1877px 402px var(--stars),
        708px 1778px var(--stars), 903px 1625px var(--stars),
        1824px 352px var(--stars), 1229px 140px var(--stars),
        1518px 24px var(--stars), 1017px 512px var(--stars),
        515px 699px var(--stars), 295px 265px var(--stars),
        69px 1773px var(--stars), 1640px 1163px var(--stars),
        536px 342px var(--stars), 970px 1766px var(--stars),
        560px 1416px var(--stars), 577px 193px var(--stars),
        469px 9px var(--stars), 466px 276px var(--stars),
        711px 853px var(--stars), 401px 685px var(--stars),
        85px 506px var(--stars), 865px 558px var(--stars),
        631px 105px var(--stars), 887px 866px var(--stars),
        1704px 1001px var(--stars), 1051px 1199px var(--stars),
        275px 1909px var(--stars), 1462px 829px var(--stars),
        375px 1057px var(--stars), 1531px 1501px var(--stars),
        205px 403px var(--stars), 33px 1869px var(--stars),
        967px 1176px var(--stars), 376px 863px var(--stars),
        1769px 1545px var(--stars), 535px 51px var(--stars),
        1972px 1569px var(--stars), 1773px 960px var(--stars),
        487px 620px var(--stars), 1660px 687px var(--stars),
        1632px 972px var(--stars), 1362px 42px var(--stars),
        479px 1655px var(--stars), 1531px 1808px var(--stars),
        1450px 1412px var(--stars), 1549px 170px var(--stars),
        1904px 1305px var(--stars), 1209px 48px var(--stars),
        1933px 820px var(--stars), 1623px 595px var(--stars),
        48px 643px var(--stars), 179px 1754px var(--stars),
        589px 1032px var(--stars), 1199px 356px var(--stars),
        1755px 1418px var(--stars), 780px 1174px var(--stars),
        1905px 758px var(--stars), 1567px 713px var(--stars),
        1372px 705px var(--stars), 456px 654px var(--stars),
        759px 690px var(--stars), 452px 673px var(--stars),
        993px 1610px var(--stars), 1271px 188px var(--stars),
        343px 1750px var(--stars), 1943px 1735px var(--stars),
        1717px 853px var(--stars), 1247px 303px var(--stars),
        1314px 1895px var(--stars), 1203px 489px var(--stars),
        741px 469px var(--stars), 4px 246px var(--stars),
        1515px 115px var(--stars), 606px 218px var(--stars),
        1966px 1471px var(--stars), 177px 87px var(--stars),
        1575px 588px var(--stars), 1136px 1386px var(--stars),
        70px 1868px var(--stars), 1053px 18px var(--stars),
        1124px 721px var(--stars), 1748px 1181px var(--stars),
        191px 1387px var(--stars), 1931px 840px var(--stars),
        1088px 1603px var(--stars), 634px 1255px var(--stars),
        814px 1434px var(--stars), 585px 64px var(--stars),
        1074px 1618px var(--stars), 1692px 761px var(--stars),
        651px 643px var(--stars), 193px 335px var(--stars),
        1103px 1447px var(--stars), 491px 1142px var(--stars),
        521px 408px var(--stars), 536px 340px var(--stars),
        411px 1091px var(--stars), 1646px 193px var(--stars),
        1595px 1285px var(--stars), 870px 1349px var(--stars),
        1085px 1013px var(--stars), 204px 1864px var(--stars),
        1359px 299px var(--stars), 807px 964px var(--stars),
        219px 509px var(--stars), 36px 1227px var(--stars),
        702px 1873px var(--stars), 1471px 934px var(--stars),
        1763px 792px var(--stars), 973px 1957px var(--stars),
        987px 68px var(--stars), 593px 1282px var(--stars),
        1900px 607px var(--stars), 407px 1659px var(--stars),
        587px 17px var(--stars), 632px 158px var(--stars);
    animation: animStar 600s linear infinite;
}
#stars4:after {
    content: ' ';
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 233px 1976px var(--stars), 1196px 1119px var(--stars),
        646px 740px var(--stars), 335px 645px var(--stars),
        1119px 1452px var(--stars), 176px 1870pxvar (--stars),
        639px 1711px var(--stars), 647px 1388px var(--stars),
        1516px 1108px var(--stars), 464px 66px var(--stars),
        331px 344px var(--stars), 772px 1189px var(--stars),
        1516px 1850px var(--stars), 1500px 1463px var(--stars),
        1275px 876px var(--stars), 1107px 645px var(--stars),
        977px 478px var(--stars), 583px 1179px var(--stars),
        284px 395px var(--stars), 1220px 461px var(--stars),
        1160px 249px var(--stars), 196px 865px var(--stars),
        670px 1915px var(--stars), 1449px 382px var(--stars),
        1191px 546px var(--stars), 1329px 605px var(--stars),
        1945px 458px var(--stars), 995px 749px var(--stars),
        1495px 861px var(--stars), 708px 1731px var(--stars),
        348px 653px var(--stars), 548px 1298px var(--stars),
        1606px 990px var(--stars), 1049px 1204px var(--stars),
        253px 1501px var(--stars), 1154px 166px var(--stars),
        1087px 104px var(--stars), 1034px 1161px var(--stars),
        1681px 462px var(--stars), 577px 1897px var(--stars),
        193px 1901px var(--stars), 1701px 1755px var(--stars),
        864px 1297px var(--stars), 800px 1289px var(--stars),
        676px 28px var(--stars), 185px 1341px var(--stars),
        379px 1151px var(--stars), 1224px 1725px var(--stars),
        280px 541px var(--stars), 473px 1196px var(--stars),
        921px 1628px var(--stars), 969px 432px var(--stars),
        1475px 758px var(--stars), 1195px 993px var(--stars),
        876px 1840px var(--stars), 1274px 1689px var(--stars),
        1977px 1101px var(--stars), 837px 527px var(--stars),
        1785px 1610px var(--stars), 1650px 1843px var(--stars),
        1127px 1508px var(--stars), 401px 1050px var(--stars),
        51px 1105px var(--stars), 545px 880px var(--stars),
        1786px 1672px var(--stars), 318px 260px var(--stars),
        568px 254px var(--stars), 1026px 1527px var(--stars),
        1242px 852px var(--stars), 1785px 982px var(--stars),
        1318px 1071px var(--stars), 398px 1061px var(--stars),
        1509px 257px var(--stars), 599px 928px var(--stars),
        1195px 1800px var(--stars), 1254px 906px var(--stars),
        141px 26px var(--stars), 1384px 1502px var(--stars),
        476px 767px var(--stars), 1973px 722px var(--stars),
        1339px 1031px var(--stars), 778px 818px var(--stars),
        213px 1320px var(--stars), 184px 221px var(--stars),
        983px 1911px var(--stars), 923px 1439px var(--stars),
        1936px 581px var(--stars), 1105px 625px var(--stars),
        325px 729px var(--stars), 1475px 204px var(--stars),
        1483px 1564px var(--stars), 1327px 1272px var(--stars),
        1187px 1944px var(--stars), 1945px 1471px var(--stars),
        116px 960px var(--stars), 1660px 1610px var(--stars),
        412px 1022px var(--stars), 1552px 1516px var(--stars),
        1517px 1892px var(--stars), 306px 829px var(--stars),
        1416px 462px var(--stars), 1575px 1460px var(--stars),
        424px 1500px var(--stars), 1530px 1169px var(--stars),
        1388px 1608px var(--stars), 185px 416px var(--stars),
        634px 1446px var(--stars), 767px 479px var(--stars),
        71px 426px var(--stars), 1937px 145px var(--stars),
        1955px 1312px var(--stars), 1811px 611px var(--stars),
        1145px 569px var(--stars), 1460px 676px var(--stars),
        131px 1858px var(--stars), 1557px 473px var(--stars),
        735px 130px var(--stars), 112px 1531px var(--stars),
        1312px 305px var(--stars), 409px 1032px var(--stars),
        149px 1964px var(--stars), 535px 1215px var(--stars),
        1382px 630px var(--stars), 1437px 1368px var(--stars),
        362px 1181px var(--stars), 388px 181px var(--stars),
        274px 1287px var(--stars), 1858px 1414px var(--stars),
        661px 1935px var(--stars), 675px 1205px var(--stars),
        1829px 1725px var(--stars), 1937px 1145px var(--stars),
        237px 908px var(--stars), 1059px 1185px var(--stars),
        824px 1248px var(--stars), 1167px 1730px var(--stars),
        180px 1961px var(--stars), 1663px 203px var(--stars),
        374px 221px var(--stars), 724px 1883px var(--stars),
        970px 1362px var(--stars), 832px 505px var(--stars),
        313px 233px var(--stars), 1909px 597px var(--stars),
        434px 201px var(--stars), 587px 995px var(--stars),
        1833px 623px var(--stars), 1464px 561px var(--stars),
        231px 593px var(--stars), 1558px 1433px var(--stars),
        1986px 1767px var(--stars), 1753px 1728px var(--stars),
        1153px 1623px var(--stars), 249px 229px var(--stars),
        1503px 1186px var(--stars), 1784px 137px var(--stars),
        841px 403px var(--stars), 1400px 354px var(--stars),
        197px 499px var(--stars), 1188px 681px var(--stars),
        158px 391px var(--stars), 443px 1099px var(--stars),
        723px 1445px var(--stars), 1408px 1235px var(--stars),
        1908px 195px var(--stars), 271px 891px var(--stars),
        469px 1693px var(--stars), 580px 11px var(--stars),
        1533px 70px var(--stars), 859px 761px var(--stars),
        1510px 1844px var(--stars), 421px 558px var(--stars),
        1132px 1453px var(--stars), 757px 1987px var(--stars),
        212px 293px var(--stars), 569px 323px var(--stars),
        1404px 1394px var(--stars), 252px 1386px var(--stars),
        1668px 1857px var(--stars), 123px 1684px var(--stars),
        105px 490px var(--stars), 1083px 1769px var(--stars),
        1071px 1953px var(--stars), 1271px 1159px var(--stars),
        699px 1491px var(--stars), 1744px 1997px var(--stars),
        1868px 1973px var(--stars), 1438px 1449px var(--stars),
        1222px 1921px var(--stars), 1328px 1210px var(--stars),
        438px 873px var(--stars), 809px 780px var(--stars),
        491px 1524px var(--stars), 447px 1830px var(--stars),
        927px 1936px var(--stars), 564px 691px var(--stars),
        1784px 1747px var(--stars), 1978px 1722px var(--stars),
        1599px 1480px var(--stars), 1276px 729px var(--stars),
        731px 1174px var(--stars), 1586px 1711px var(--stars),
        451px 1340px var(--stars), 1075px 1899px var(--stars),
        13px 575px var(--stars), 309px 1340px var(--stars),
        981px 183px var(--stars), 248px 1315px var(--stars),
        849px 80px var(--stars), 1754px 1540px var(--stars),
        73px 1432px var(--stars), 1208px 1828px var(--stars),
        65px 575px var(--stars), 1098px 730px var(--stars),
        127px 1358px var(--stars), 185px 19px var(--stars),
        1222px 1679px var(--stars), 1122px 315px var(--stars),
        1906px 452px var(--stars), 761px 284px var(--stars),
        813px 492px var(--stars), 1344px 843px var(--stars),
        118px 1834px var(--stars), 1620px 359px var(--stars),
        1755px 1246px var(--stars), 299px 1076px var(--stars),
        1746px 158px var(--stars), 6px 1635px var(--stars),
        143px 190px var(--stars), 101px 468px var(--stars),
        137px 971px var(--stars), 1221px 1929px var(--stars),
        1752px 650px var(--stars), 1635px 1761px var(--stars),
        1522px 833px var(--stars), 908px 153px var(--stars),
        1044px 350px var(--stars), 1151px 1940px var(--stars),
        822px 210px var(--stars), 1774px 310px var(--stars),
        796px 1447px var(--stars), 1069px 1903px var(--stars),
        217px 565px var(--stars), 662px 1370px var(--stars),
        1876px 1570px var(--stars), 847px 46px var(--stars),
        1042px 1689px var(--stars), 1584px 1434px var(--stars),
        1791px 908px var(--stars), 973px 908px var(--stars),
        793px 747px var(--stars), 122px 483px var(--stars),
        1137px 1374px var(--stars), 1757px 1791px var(--stars),
        513px 225px var(--stars), 63px 731px var(--stars),
        1179px 1926px var(--stars), 346px 18px var(--stars),
        589px 175px var(--stars), 87px 302px var(--stars),
        380px 1295px var(--stars), 450px 921px var(--stars),
        1667px 1973px var(--stars), 1495px 1373px var(--stars),
        1462px 1850px var(--stars), 540px 288px var(--stars),
        1208px 1051px var(--stars), 1554px 1095px var(--stars),
        1009px 1516px var(--stars), 181px 572px var(--stars),
        165px 387px var(--stars), 549px 1835px var(--stars),
        960px 16px var(--stars), 1360px 403px var(--stars),
        1251px 43px var(--stars), 1905px 1813px var(--stars),
        1106px 866px var(--stars), 1809px 277px var(--stars),
        1828px 1720px var(--stars), 295px 1610px var(--stars),
        523px 166px var(--stars), 1069px 692px var(--stars),
        1292px 217px var(--stars), 11px 1721px var(--stars),
        99px 1045px var(--stars), 51px 1584px var(--stars),
        1053px 266px var(--stars), 1287px 1235px var(--stars),
        747px 1722px var(--stars), 1542px 736px var(--stars),
        1256px 18px var(--stars), 102px 609px var(--stars),
        586px 1339px var(--stars), 1843px 1697px var(--stars),
        824px 1687px var(--stars), 1124px 882px var(--stars),
        395px 501px var(--stars), 1456px 672px var(--stars),
        1472px 1648px var(--stars), 1326px 1164px var(--stars),
        777px 1672px var(--stars), 81px 345px var(--stars),
        91px 386px var(--stars), 243px 411px var(--stars),
        1560px 90px var(--stars), 6px 1771px var(--stars),
        1601px 616px var(--stars), 1220px 1808px var(--stars),
        1160px 836px var(--stars), 246px 1777px var(--stars),
        456px 863px var(--stars), 97px 1138px var(--stars),
        1811px 942px var(--stars), 213px 414px var(--stars),
        891px 392px var(--stars), 1044px 927px var(--stars),
        1856px 216px var(--stars), 957px 347px var(--stars),
        1486px 406px var(--stars), 838px 912px var(--stars),
        803px 361px var(--stars), 564px 826px var(--stars),
        1597px 949px var(--stars), 1206px 289px var(--stars),
        33px 1035px var(--stars), 1762px 1377px var(--stars),
        789px 1815px var(--stars), 1594px 1342px var(--stars),
        1668px 880px var(--stars), 1539px 1581px var(--stars),
        1547px 53px var(--stars), 861px 1433px var(--stars),
        693px 1618px var(--stars), 1762px 782px var(--stars),
        1568px 682px var(--stars), 1126px 1762px var(--stars),
        1242px 134px var(--stars), 495px 959px var(--stars),
        1606px 219px var(--stars), 1878px 1415px var(--stars),
        1652px 797px var(--stars), 782px 1903px var(--stars),
        1774px 1133px var(--stars), 1430px 408px var(--stars),
        265px 394px var(--stars), 890px 336px var(--stars),
        1051px 311px var(--stars), 461px 1559px var(--stars),
        1931px 91px var(--stars), 1160px 380px var(--stars),
        1442px 1058px var(--stars), 1157px 364px var(--stars),
        586px 227px var(--stars), 1365px 715px var(--stars),
        1658px 1655px var(--stars), 1923px 1664px var(--stars),
        1023px 1844px var(--stars), 1939px 1367px var(--stars),
        1203px 1305px var(--stars), 359px 642px var(--stars),
        1056px 425px var(--stars), 787px 202px var(--stars),
        1609px 1850px var(--stars), 1964px 200px var(--stars),
        1537px 586px var(--stars), 1589px 903px var(--stars),
        1063px 1694px var(--stars), 760px 1185px var(--stars),
        597px 1396px var(--stars), 294px 452px var(--stars),
        433px 818px var(--stars), 199px 840px var(--stars),
        1332px 1937px var(--stars), 169px 1907px var(--stars),
        591px 834px var(--stars), 1716px 1032px var(--stars),
        45px 1879px var(--stars), 686px 1469px var(--stars),
        1520px 475px var(--stars), 1122px 859px var(--stars),
        973px 1541px var(--stars), 269px 477px var(--stars),
        1390px 716px var(--stars), 1791px 783px var(--stars),
        824px 2000px var(--stars), 1211px 1717px var(--stars),
        1008px 1587px var(--stars), 1422px 204px var(--stars),
        234px 556px var(--stars), 506px 550px var(--stars),
        942px 1670px var(--stars), 397px 853px var(--stars),
        599px 795px var(--stars), 762px 1926px var(--stars),
        1202px 1424px var(--stars), 135px 1316px var(--stars),
        1442px 1692px var(--stars), 977px 652px var(--stars),
        564px 1648px var(--stars), 997px 1474px var(--stars),
        67px 1366px var(--stars), 1860px 1451px var(--stars),
        1105px 772px var(--stars), 1886px 1396px var(--stars),
        1510px 658px var(--stars), 976px 1544px var(--stars),
        894px 543px var(--stars), 1098px 1189px var(--stars),
        690px 77px var(--stars), 770px 733px var(--stars),
        557px 1403px var(--stars), 1758px 1623px var(--stars),
        1341px 812px var(--stars), 699px 967px var(--stars),
        277px 866px var(--stars), 1526px 1828px var(--stars),
        8px 977px var(--stars), 1707px 952px var(--stars),
        12px 1900px var(--stars), 72px 921px var(--stars),
        496px 1067px var(--stars), 1288px 1749px var(--stars),
        273px 984px var(--stars), 1197px 1991px var(--stars),
        242px 789px var(--stars), 903px 1035px var(--stars),
        480px 1492px var(--stars), 102px 1331px var(--stars),
        738px 1343px var(--stars), 560px 1475px var(--stars),
        367px 846px var(--stars), 1420px 962px var(--stars),
        1976px 892px var(--stars), 1911px 1763px var(--stars),
        1639px 1002px var(--stars), 437px 1522px var(--stars),
        1906px 1025px var(--stars), 730px 1364px var(--stars),
        1127px 521px var(--stars), 1401px 1792px var(--stars),
        1954px 1066px var(--stars), 232px 250px var(--stars),
        1685px 660px var(--stars), 1011px 999px var(--stars),
        1970px 790px var(--stars), 750px 499px var(--stars),
        1738px 660px var(--stars), 1621px 1849px var(--stars),
        446px 52px var(--stars), 1055px 1396px var(--stars),
        1165px 1497px var(--stars), 1740px 1425px var(--stars),
        1012px 1920px var(--stars), 1258px 1560px var(--stars),
        1020px 1152px var(--stars), 362px 673px var(--stars),
        1065px 975px var(--stars), 582px 755px var(--stars),
        1271px 1479px var(--stars), 719px 548px var(--stars),
        1602px 879px var(--stars), 590px 499px var(--stars),
        721px 1412px var(--stars), 1180px 113px var(--stars),
        1801px 1961px var(--stars), 589px 941px var(--stars),
        883px 476px var(--stars), 214px 890px var(--stars),
        1028px 892px var(--stars), 1107px 1832px var(--stars),
        944px 361px var(--stars), 480px 1453px var(--stars),
        1466px 683px var(--stars), 981px 745px var(--stars),
        1968px 828px var(--stars), 657px 1830px var(--stars),
        11px 1338px var(--stars), 179px 730px var(--stars),
        1713px 197px var(--stars), 51px 955px var(--stars),
        1243px 319px var(--stars), 1175px 624px var(--stars),
        446px 46px var(--stars), 5px 1158px var(--stars),
        82px 1352px var(--stars), 1877px 402px var(--stars),
        708px 1778px var(--stars), 903px 1625px var(--stars),
        1824px 352px var(--stars), 1229px 140px var(--stars),
        1518px 24px var(--stars), 1017px 512px var(--stars),
        515px 699px var(--stars), 295px 265px var(--stars),
        69px 1773px var(--stars), 1640px 1163px var(--stars),
        536px 342px var(--stars), 970px 1766px var(--stars),
        560px 1416px var(--stars), 577px 193px var(--stars),
        469px 9px var(--stars), 466px 276px var(--stars),
        711px 853px var(--stars), 401px 685px var(--stars),
        85px 506px var(--stars), 865px 558px var(--stars),
        631px 105px var(--stars), 887px 866px var(--stars),
        1704px 1001px var(--stars), 1051px 1199px var(--stars),
        275px 1909px var(--stars), 1462px 829px var(--stars),
        375px 1057px var(--stars), 1531px 1501px var(--stars),
        205px 403px var(--stars), 33px 1869px var(--stars),
        967px 1176px var(--stars), 376px 863px var(--stars),
        1769px 1545px var(--stars), 535px 51px var(--stars),
        1972px 1569px var(--stars), 1773px 960px var(--stars),
        487px 620px var(--stars), 1660px 687px var(--stars),
        1632px 972px var(--stars), 1362px 42px var(--stars),
        479px 1655px var(--stars), 1531px 1808px var(--stars),
        1450px 1412px var(--stars), 1549px 170px var(--stars),
        1904px 1305px var(--stars), 1209px 48px var(--stars),
        1933px 820px var(--stars), 1623px 595px var(--stars),
        48px 643px var(--stars), 179px 1754px var(--stars),
        589px 1032px var(--stars), 1199px 356px var(--stars),
        1755px 1418px var(--stars), 780px 1174px var(--stars),
        1905px 758px var(--stars), 1567px 713px var(--stars),
        1372px 705px var(--stars), 456px 654px var(--stars),
        759px 690px var(--stars), 452px 673px var(--stars),
        993px 1610px var(--stars), 1271px 188px var(--stars),
        343px 1750px var(--stars), 1943px 1735px var(--stars),
        1717px 853px var(--stars), 1247px 303px var(--stars),
        1314px 1895px var(--stars), 1203px 489px var(--stars),
        741px 469px var(--stars), 4px 246px var(--stars),
        1515px 115px var(--stars), 606px 218px var(--stars),
        1966px 1471px var(--stars), 177px 87px var(--stars),
        1575px 588px var(--stars), 1136px 1386px var(--stars),
        70px 1868px var(--stars), 1053px 18px var(--stars),
        1124px 721px var(--stars), 1748px 1181px var(--stars),
        191px 1387px var(--stars), 1931px 840px var(--stars),
        1088px 1603px var(--stars), 634px 1255px var(--stars),
        814px 1434px var(--stars), 585px 64px var(--stars),
        1074px 1618px var(--stars), 1692px 761px var(--stars),
        651px 643px var(--stars), 193px 335px var(--stars),
        1103px 1447px var(--stars), 491px 1142px var(--stars),
        521px 408px var(--stars), 536px 340px var(--stars),
        411px 1091px var(--stars), 1646px 193px var(--stars),
        1595px 1285px var(--stars), 870px 1349px var(--stars),
        1085px 1013px var(--stars), 204px 1864px var(--stars),
        1359px 299px var(--stars), 807px 964px var(--stars),
        219px 509px var(--stars), 36px 1227px var(--stars),
        702px 1873px var(--stars), 1471px 934px var(--stars),
        1763px 792px var(--stars), 973px 1957px var(--stars),
        987px 68px var(--stars), 593px 1282px var(--stars),
        1900px 607px var(--stars), 407px 1659px var(--stars),
        587px 17px var(--stars), 632px 158px var(--stars);
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(-2000px);
    }
}
