:root {
    --background: #202124;
    --background2: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    --foreground: #e8eaed;
    --hover-nav: rgba(232, 234, 237, 0.04);
    --text-secondary: #9aa0a6;
    --nav-shadow: #141719;
    --bg-buttons: #604a84;
    --text-buttons: #f8faff;
    --socials-buttons: #a8acb3;
    --stars: #e8eaed;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    scroll-behavior: smooth;
    box-sizing: border-box;
    width: 100%;
    display: block;
}

body {
    background-color: var(--background);
    color: var(--foreground);
}

section {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.section__tittle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2.5rem;
    margin-top: 4rem;
}

.section__tittle h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
}

.section__tittle > span {
    color: var(--text-secondary);
    font-size: 1rem;
    line-height: 1.5rem;
}
