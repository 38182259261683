.chip {
    font-weight: 700;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    --tw-text-opacity: 1;
    border-radius: 0.5rem;
}

.grey {
    background-color: rgb(107 114 128);
}

.purple {
    background-color: var(--bg-buttons);
}
