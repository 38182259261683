#aboutMe {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px 32px 0px 32px;
    width: 100%;
}

.aboutMe__description {
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutMe__description p {
    margin-top: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    width: 80%;
}

.aboutMe__description span {
    font-weight: 700;
}

.aboutMe__description a {
    font-weight: 700;
    text-decoration: none;
    color: var(--foreground);
}

.aboutMe__background {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    margin-top: 2rem;
}

.showEducation__button {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    width: fit-content;
    border-bottom: solid 2px var(--bg-buttons);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    background-color: transparent;
    color: var(--text-buttons);
}

.showEducation__button img {
    width: 2.5rem;
    height: 2.5rem;
}

.showEducation__button--disabled {
    border: 0;
}

.skill__type {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: center;
    max-width: 50rem;
    gap: 2rem;
}

.skill__type div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.skill__type div img {
    -webkit-box-reflect: below 3px
        linear-gradient(transparent, transparent, transparent, #04060f25);
    width: 9rem;
    height: 9rem;
}

.skill__cards {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.timeLane {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.timeLane ol {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2.5rem;
    list-style: none;
}

.timeLane ol li {
    display: flex;
    flex-direction: column;
    height: 10rem;
    width: 100%;
    position: relative;
}

.timeLane__spot {
    display: flex;
    align-items: center;
    width: 100%;
}

.timeLane__spot__icon {
    display: flex;
    background-color: rgb(229 231 235);
    border-radius: 100px;
    width: 2rem;
    height: 2.25rem;
    align-items: center;
    justify-content: center;
}

.timeLane__spot__lane {
    display: flex;
    background-color: var(--bg-buttons);
    width: 100%;
    height: 0.125rem;
}

.timeLane__description {
    margin-top: 0.75rem;
    padding-right: 2rem;
}

.timeLane__description h1 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
}

.timeLane__description time {
    color: var(--text-secondary);
    line-height: 1;
    font-weight: 400;
    font-size: 0.75;
    display: block;
    margin-bottom: 0.5rem;
}

.timeLane__description p {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

@media (max-width: 785px) {
    .timeLane ol {
        flex-direction: column;
    }

    .timeLane ol li {
        margin-bottom: 2rem;
    }
}

@media (max-width: 730px) {
    .skill__type {
        display: flex;
        justify-items: center;
        flex-direction: column;
    }

    .skill__cards {
        margin-bottom: 2rem;
    }
}
