.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0 20px;
    width: 100%;
    height: 72px;
    background-color: var(--background);
    color: var(--foreground);
    transition: 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header--disabled {
    display: none;
}

.navbar-links {
    display: flex;
    align-items: center;
}

.navbar-links ul,
.navbar-links--active ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links li,
.navbar-links--active li {
    list-style: none;
    transition: 0.3s ease-in-out;
}

.navbar-links li:hover,
.navbar-links--active li:hover {
    background-color: rgba(255, 255, 255, 0.027);
}

.navbar-links li a,
.navbar-links--active li a {
    text-decoration: none;
    color: var(--foreground);
    padding: 1rem;
    display: block;
}

.burgerButton,
.burgerButton--active {
    display: none;
}

@media (max-width: 440px) {
    .burgerButton,
    .burgerButton--active {
        display: flex;
        margin-top: 20px;
        margin-right: 20px;
    }

    .navbar-links {
        display: none;
    }

    .navbar-links--active {
        display: flex;
    }

    .navbar-links--active ul {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: fixed;
        top: 68px;
        right: 0px;
        width: 100%;
        height: 100vh;
        background-color: rgba(22, 22, 22, 0.884);

        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 0px;
    }
    .navbar-links--active li {
        width: 100%;
    }

    .navbar-links--active li a {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
