.contactMe {
    padding-top: 80px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
    max-width: var(--mw-document);
    margin: 0 auto;
    padding: var(--p-u-center);
    position: relative;

    margin-left: 2rem;
    margin-right: 2rem;
    gap: 2rem;
}

.contactMe__actionCall {
    width: 50%;
}

.contactMe__actionCall h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.contactMe__actionCall__contactMe {
    font-size: 3.75rem;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 700;
    padding: 0.85rem;
    background-color: var(--bg-buttons);
    border-radius: 0.375rem;
    width: max-content;
    margin-top: 0.5rem;
    position: relative;
    animation: slide 10s step-start infinite;
}

.contactMe__actionCall__contactMe::after {
    content: '|';
    background-color: var(--bg-buttons);
    border-left-width: 4px;
    position: absolute;
    right: 0;

    animation-duration: 3s;
    animation-timing-function: steps(35);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: writting;
}

.contactMe__actionCall p {
    text-align: start;
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.contactMe__actionCall a {
    display: flex;
    justify-content: flex-start;
    font-size: 1.25rem;
    line-height: 1.75rem;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.75rem;
    text-decoration: none;
    color: inherit;
}

.contactMe__actionCall a > img {
    width: 2rem;
    height: 2rem;
}

.contactMe__actionCall a > p {
    font-weight: 700;
}

.contactMe__Form {
    width: 30rem;
    display: block;
    --p-separation: 0;
    --w-aside: 100%;
    --mw-document: 100%;
    color-scheme: dark;
    line-height: inherit;
    tab-size: 4;
    font-feature-settings: normal;
}

.contactMe__Form form {
    width: 100%;
    margin-top: 1rem;
}

.contactMe__Form input,
.contactMe__Form textarea {
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid var(--foreground);
    border-radius: 0.375rem;
    width: 100%;
    margin-top: 0.5rem;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

.contactMe__Form__sendButton {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    background-color: var(--bg-buttons);
    border-radius: 0.375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4rem;
    margin-top: 0.5rem;
}

.contactMe__Form__sendButton button {
    font-size: 1.5rem;
    line-height: 2rem;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-transform: none;
}

.contactMe__Form__sendButton button > div {
    gap: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.contactMe__Form__alert {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.contactMe__Form__alert div {
    text-align: center;
    padding: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(22 163 74 / var(--tw-bg-opacity));
    border-radius: 0.375rem;
    width: 100%;
}

.hidden {
    display: none;
}

@keyframes writting {
    0% {
        width: 100%;
    }
    100% {
        width: 1%;
    }
}

@media (max-width: 850px) {
    .contactMe {
        flex-direction: column;
        justify-content: center;
    }

    .contactMe__actionCall {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contactMe__actionCall h1 {
        align-items: center;
    }

    .contactMe__Form {
        width: 100%;
    }
}
